import { useState, useEffect } from 'react';

import OtherOrgOrgUnitTeamNameData from './OtherOrgOrgUnitTeamNameData';

const useOtherOrgOrgUnitTeamNameData = () => {
  const [otherOrgOrgUnitTeamNameState, setOtherOrgOrgUnitTeamNameState] = useState(OtherOrgOrgUnitTeamNameData.INITIAL_STATE);
  const getOtherOrgOrgUnits = () => OtherOrgOrgUnitTeamNameData.getOtherOrgOrgUnits(otherOrgOrgUnitTeamNameState, setOtherOrgOrgUnitTeamNameState);

  useEffect(() => {
    getOtherOrgOrgUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    otherOrgOrgUnitTeamNameState,
    getOtherOrgOrgUnits
  };
};

export default useOtherOrgOrgUnitTeamNameData;