import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useMeetSessionsData from '../../../../../common/state/meetSessions/UseMeetSessionsData';

import Constants from '../../../../../common/utils/Constants';

const useMeetSessions = () => {
  const navigate = useNavigate();
  const { meetState, putMeet } = useMeetData();
  const { resetModalState } = useMeetSessionsData();
  const [state, setState] = useState({ error: '', tryRedirect: false });

  const onAddSession = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.TEAM_MEET_ADD_SESSIONS_DETAIL);
  }

  const onEditSession = (e, session) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.TEAM_MEET_ADD_SESSIONS_DETAIL, { state: { meetSessionId: session.meetSessionId } });
  }

  const onDeleteSession = (e, meetSessionId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const updatedMeetCopy = JSON.parse(JSON.stringify(meetState.objData));
    const selectedIndex = updatedMeetCopy.meetSession.findIndex(x => x.meetSessionId === meetSessionId);
    if (selectedIndex > -1) {
      updatedMeetCopy.meetSession.splice(selectedIndex, 1);
      resetModalState();
      putMeet(meetState.objData.meetId, updatedMeetCopy);
    }
  }

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (meetState?.objData.meetSession.length > 0) {
      setState({ ...state, tryRedirect: true });
    }
    else {
      setState({ ...state, error: 'At least one Session is required.' });
    }
  };

  useEffect(() => {
    if (state.tryRedirect === true) {
      navigate(NavLinks.TEAM_MEET, { state: { getMeets: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return {
    state,
    meetState,
    isLoading: meetState.isObjLoading,
    isSaving: meetState.isSaving,
    Constants,
    onAddSession,
    onEditSession,
    onDeleteSession,
    onContinueClicked
  };
}

export default useMeetSessions;