import React, { Fragment } from 'react';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';

const LargeGrid = ({ state, gridState }) => (
  <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
    <thead>
      <tr>
        <th>Meet Name</th>
        <th>Meet Dates</th>
        <th>Meet Host</th>
        <th>Course</th>
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true
        ? (
          <tr>
            {state.isArrayLoading === true ?
              <td colSpan="4">Loading...</td>
              : <td colSpan="4">No Meets</td>
            }
          </tr>
        ) : Array.isArray(gridState) && gridState.length > 0
          ? gridState.map(meet => (
            <tr key={meet.meetId}>
              <td>{meet.meetName}</td>
              <td>{formatDate(meet.startDate)} - {formatDate(meet.endDate)}</td>
              <td>{meet.orgUnitName}</td>
              <td>{meet.meetCourse}</td>
            </tr>
          )) : (
            <tr>
              <td colSpan="4">No Meets</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

const SmallGrid = ({ state, gridState }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Meets</div>
            }
          </div>
        </div>
      ) : Array.isArray(gridState) && gridState.length > 0
        ? gridState.map(meet => (
          <div className={global.SmallTableRow} key={meet.meetId}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{meet.meetName}</span>&nbsp;
            </div>
            <div className={['row', global.SmallTableRowInfo].join(' ')}>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Meet Name</div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{meet.meetName}</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet Dates</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatDate(meet.startDate)} - {formatDate(meet.endDate)}</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet Host</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.orgUnitName}</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Course</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.meetCourse}</div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Meets</div>
            </div>
          </div>
        )
    }
  </div >
);

const TeamMeetGridRead = ({ state, gridState }) => (
  <Fragment>
    <LargeGrid state={state} gridState={gridState} />
    <SmallGrid state={state} gridState={gridState} />
  </Fragment>
);

export default TeamMeetGridRead;