import React, { createContext, useState } from 'react';

import ReportPeriodData from './ReportPeriodData';

export const ReportPeriodStateContext = createContext();

const ReportPeriodContextProvider = ({ children }) => {
  const stateHook = useState(ReportPeriodData.INITIAL_STATE);

  return (
    <ReportPeriodStateContext.Provider value={stateHook}>
      {children}
    </ReportPeriodStateContext.Provider>
  );
};

export default ReportPeriodContextProvider;