import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import UnlinkIcon from '../../../../common/components/icons/UnlinkIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import AddPoolIcon from '../../../../common/components/icons/AddPoolIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ pool, onEditPool, onDeletePool }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{pool.facilityPoolName && pool.facilityPoolName.trim() !== '' ? pool.facilityPoolName : <span>&nbsp;</span>}</span>&nbsp;
      <button
        type="button"
        className={global.IconButton}
        onClick={(e) => onDeletePool(e, pool)}>
        <DeleteIcon />
      </button>
      <button
        type="button"
        className={global.IconButtonMobileMargin}
        onClick={(e) => onEditPool(e, pool.facilityId, pool.facilityPoolId)}>
        <EditIcon />
      </button>
    </div>
    <div className={['row', global.SmallTableRowInfo].join(' ')}>
      <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Pool Name</div>
      <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>{pool.facilityPoolName || <span>&nbsp;</span>}</div>
      <div className={['col-xs-6 col-sm-6', global.SmallTableRowLabels].join(' ')}>Pool Dimensions</div>
      <div className={['col-xs-6 col-sm-6', global.SmallTableRowData].join(' ')}>{pool.poolDimensions ? pool.poolDimensions.dimensionName : <span>&nbsp;</span>}</div>
    </div>
  </div>
);

const GridRowDetailTable = ({ facility, onEditPool, onDeletePool }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(facility.facilityPool) && facility.facilityPool.map((pool, i) =>
      <DetailTableRow key={i} pool={pool} onEditPool={onEditPool} onDeletePool={onDeletePool} />)}
  </div>
);

const GridRow = ({ index, orgUnitFacility, onEdit, onDelete, onAddPool, onEditPool, onDeletePool, expandedId, onClick }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow} key={orgUnitFacility.facility?.facilityId > 0 ? orgUnitFacility.facility.facilityId : index}>
        <div className={global.SmallTableRowHead}>
          <span className="hidden-xs">{orgUnitFacility.facility?.facilityName}</span>&nbsp;
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, orgUnitFacility.facility?.facilityId)}>
            {expandedId === orgUnitFacility.facility?.facilityId ? <HideIcon /> : <ShowIcon />}
          </button>
          <button
            className={global.IconButtonMobileMargin}
            type="button"
            onClick={(e) => onAddPool(e, orgUnitFacility.facility.facilityId)}>
            <AddPoolIcon />
          </button>
          <button
            type="button"
            className={global.IconButtonMobileMargin}
            onClick={(e) => onDelete(e, orgUnitFacility.facility?.facilityId, `${orgUnitFacility.facility?.facilityName}`)}>
            <UnlinkIcon />
          </button>
          <button
            type="button"
            className={global.IconButtonMobileMargin}
            onClick={(e) => onEdit(e, orgUnitFacility.facility?.facilityId)}>
            <EditIcon />
          </button>
        </div>
        <div className={['row', global.SmallTableRowInfo].join(' ')}>
          <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Facility Name</div>
          <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{orgUnitFacility.facility?.facilityName || <span>&nbsp;</span>}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>City</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{orgUnitFacility.facility?.city}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>State</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{orgUnitFacility.facility?.stateCode}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Zip Code</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{orgUnitFacility.facility?.postalCode}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Address</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{orgUnitFacility.facility?.address1}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Purpose</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{orgUnitFacility.trainingFacility === true ? 'Training' : 'Competition'}</div>
        </div>
      </div>
      {Array.isArray(orgUnitFacility.facility.facilityPool) && orgUnitFacility.facility.facilityPool.length > 0 && expandedId === orgUnitFacility.facility.facilityId &&
        <GridRowDetailTable facility={orgUnitFacility.facility} onEditPool={onEditPool} onDeletePool={onDeletePool} />}
    </Fragment>
  );
};

const SmallTeamFacilityWriteGrid = ({ state, onEdit, onDelete, onAddPool, onEditPool, onDeletePool, expandedId, onClick }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
      {state.isArrayLoaded !== true
        ? (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading... </div>
              : <div className={global.SmallTableRowLabels}>No Facilities</div>}
          </div>
        </div>
        ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
          ? state.arrayData.map((orgUnitFacility, i) => <GridRow key={i} index={i}
            orgUnitFacility={orgUnitFacility} onEdit={onEdit} onDelete={onDelete} onAddPool={onAddPool}
            onEditPool={onEditPool} onDeletePool={onDeletePool} expandedId={expandedId} onClick={onClick} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>
                &nbsp;
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Facilities</div>
              </div>
            </div>)
      }
    </div>
  </Fragment>
);

export default SmallTeamFacilityWriteGrid;