import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import UnlinkIcon from '../../../../common/components/icons/UnlinkIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import AddPoolIcon from '../../../../common/components/icons/AddPoolIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ pool, onEditPool, onDeletePool }) => {
  return (
    <tr>
      <td>{pool.facilityPoolName}</td>
      <td>{pool.poolDimensions?.dimensionName}</td>
      <td>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditPool(e, pool.facilityId, pool.facilityPoolId)}>
          <EditIcon />
        </button>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeletePool(e, pool)}>
          <DeleteIcon />
        </button></td>
    </tr>
  );
};

const DetailTable = ({ facility, onEditPool, onDeletePool }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Pool Name</th>
        <th>Pool Dimensions</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {facility.facilityPool.map((pool, i) => <DetailTableRow pool={pool} key={i} onEditPool={onEditPool} onDeletePool={onDeletePool} />)}
    </tbody>
  </table>
);

const TableRow = ({ orgUnitFacility, onEdit, onDelete, onAddPool, onEditPool, onDeletePool, expandedId, onClick }) => {
  return (
    <Fragment>
      <tr key={orgUnitFacility.facility?.facilityId}>
        <td >{orgUnitFacility.facility?.facilityName}</td>
        <td >{orgUnitFacility.facility?.city}</td>
        <td >{orgUnitFacility.facility?.stateCode}</td>
        <td >{orgUnitFacility.facility?.postalCode}</td>
        <td >{orgUnitFacility.facility?.address1}</td>
        <td>{orgUnitFacility.trainingFacility === true ? 'Training' : 'Competition'}</td>
        <td>
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, orgUnitFacility.facility?.facilityId)}>
            <EditIcon />
          </button>
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, orgUnitFacility.facility?.facilityId, `${orgUnitFacility.facility.facilityName}`)}>
            <UnlinkIcon />
          </button>
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddPool(e, orgUnitFacility.facility.facilityId)}>
            <AddPoolIcon />
          </button>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, orgUnitFacility.facility.facilityId)}>
            {expandedId === orgUnitFacility.facility.facilityId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {Array.isArray(orgUnitFacility.facility.facilityPool) && orgUnitFacility.facility.facilityPool.length > 0 && expandedId === orgUnitFacility.facility.facilityId &&
        <tr className={global.Expanded}>
          <td colSpan="7">
            <DetailTable facility={orgUnitFacility.facility} onEditPool={onEditPool} onDeletePool={onDeletePool} />
          </td>
        </tr>}
    </Fragment>
  );
};

const LargeTeamFacilityWriteGrid = ({ state, onEdit, onDelete, onAddPool, onEditPool, onDeletePool, expandedId, onClick }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
      <thead>
        <tr>
          <th>Facility Name</th>
          <th>City</th>
          <th>State</th>
          <th>Zip Code</th>
          <th>Address</th>
          <th>Purpose</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (<tr>
            {state.isArrayLoading === true
              ? <td colSpan="7">Loading...</td>
              : <td colSpan="7">No Facilities</td>}
          </tr>)
          : Array.isArray(state.arrayData)
            && state.arrayData.length > 0
            ? state.arrayData.map((facility, i) =>
              <TableRow orgUnitFacility={facility} key={i} onEdit={onEdit}
                onDelete={onDelete} onAddPool={onAddPool} onEditPool={onEditPool}
                onDeletePool={onDeletePool} expandedId={expandedId} onClick={onClick} />)
            : (<tr>
              <td colSpan="7">No Facilities</td>
            </tr>)}
      </tbody>
    </table>
  </Fragment>
);

export default LargeTeamFacilityWriteGrid;