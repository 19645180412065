import { CommonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  roleGroupId: Constants.DEFAULT_ID,
  options: []
};

const getTeamStaffRoles = (roleGroupId, state, setState) => {
  if (state.isArrayLoading !== true && roleGroupId > 0 && !state.message) {
    const newState = {
      ...state,
      roleGroupId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);
    
    const roleGroupIdForUrl = roleGroupId ? encodeURIComponent(roleGroupId) : 'NaN';
    const url = `/OrgRole/OrgRoleGroup/${roleGroupIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options: arrayData.map((role) => { return { id: role.orgRoleId, name: role.orgRoleName } }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const TeamStaffRolesData = { INITIAL_STATE, getTeamStaffRoles };

export default TeamStaffRolesData;