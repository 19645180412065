import {BASIC_INITIAL_STATE} from '../../../common/utils/HttpHelper';

import getOrganizationOrgLevelData from './GetOrganizationOrgLevelData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  treeData: []
};

const OrganizationOrgLevelData = {
  INITIAL_STATE, getOrganizationOrgLevelData
};

export default OrganizationOrgLevelData;