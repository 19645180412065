import React, { createContext, useState } from 'react';

const INITIAL_STATE = {
  filterObject: {},
  sortBy: ''
};

export const TeamFiltersStateContext = createContext();

const TeamFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <TeamFiltersStateContext.Provider value={stateHook}>
      {children}
    </TeamFiltersStateContext.Provider>
  );
};

export default TeamFiltersContextProvider;