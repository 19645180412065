import React from 'react';

import useParentOrgUnitCombobox from './UseParentOrgUnitCombobox';

import Combobox from '../../../common/components/comboboxes/Combobox';
import ReadOnly from '../../../common/components/readOnly/ReadOnly';

import global from '../../../common/components/GlobalStyle.module.css';

const ParentOrgUnitCombobox = ({ label, name, valueToMatch, error, message, onChange, orgLevelBelow }) => {
  const { parentOrgUnitState } = useParentOrgUnitCombobox({orgLevelBelow});

  if (orgLevelBelow === null) {
    return <ReadOnly
      label={label}
      name={name}
      value={"Dependant on Org Level"}/>
  }

  return parentOrgUnitState.message
    ? <span className={global.LoadingMsg}>{parentOrgUnitState.message}</span>
    : (
      <Combobox
        disabled={true}
        label={label}
        name={name}
        isLoading={parentOrgUnitState.isLoading}
        items={parentOrgUnitState.items}
        valueToMatch={valueToMatch}
        error={error}
        message={message}
        onChange={onChange} />
    );
};

export default ParentOrgUnitCombobox;