/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ actionLabel, persons, onSelected, enteredFirstName, enteredLastName, enteredBirthDate, conditionalFormatting }) => (
  <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
    <thead>
      <tr>
        <th style={{width: '100px'}}>First Name</th>
        <th style={{width: '100px'}}>Last Name</th>
        <th style={{width: '100px'}}>Birth Date</th>
        <th style={{width: '300px'}}>Organization</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th>{actionLabel}</th>
      </tr>
    </thead>
    <tbody>
      {persons.map((p, i) => (
        <tr key={i}>
          <td style={(p.firstName.toLowerCase() === (enteredFirstName ? enteredFirstName.toLowerCase() : "") || conditionalFormatting === false) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'ridge' } : {}}>{p.firstName}</td>
          <td style={(p.lastName.toLowerCase() === (enteredLastName ? enteredLastName.toLowerCase() : "")  || conditionalFormatting === false) ? { backgroundColor: 'mistyrose', borderColor: 'darkblue', border: 'ridge' } : {}}>{p.lastName}</td>
          <td style={(formatDate(p.birthDate) === formatDate(enteredBirthDate) || conditionalFormatting === false) ? { backgroundColor: 'turquoise', borderColor: 'darkblue', border: 'ridge' } : {}}>{formatDate(p.birthDate)}</td>
          <td>{p.orgDuration?.orgUnitName || ''}</td>
          <td>{p.orgDuration?.effectiveDate ? formatDate(p.orgDuration.effectiveDate) : ''}</td>
          <td>{p.orgDuration?.expirationDate ? formatDate(p.orgDuration.expirationDate) : ''}</td>
          <td>
            <Checkbox
              label=""
              name="isSelected"
              onChange={() => onSelected(p.personId)}
              checked={p.isSelected} />
          </td>
        </tr>
      ))}
    </tbody>
  </table >
);

const SmallGrid = ({ actionLabel, persons, onSelected, enteredFirstName, enteredLastName, enteredBirthDate, conditionalFormatting }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {persons.map((p, i) => (
      <div className={global.SmallTableRow} key={i}>
        <div className={global.SmallTableRowHead}>
          <div className="row">
            {/* <span className="col-sm-6 hidden-xs">{p.firstName} {p.lastName}</span> */}
            <div className="col-xs-9 col-sm-5 visible-xs">
              {actionLabel}
            </div>
            <div className={["col-xs-3 col-sm-6", global.IconButton].join(' ')}>
              <Checkbox
                label=""
                name="isSelected"
                onChange={() => onSelected(p.personId)}
                checked={p.isSelected} />
            </div>
          </div>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>First Name</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')} style={(p.firstName.toLowerCase() === (enteredFirstName ? enteredFirstName.toLowerCase() : "") || conditionalFormatting === false) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'ridge' } : {}}>
              {p.firstName}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Last Name</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}  style={(p.lastName.toLowerCase() === (enteredLastName ? enteredLastName.toLowerCase() : "")  || conditionalFormatting === false) ? { backgroundColor: 'mistyrose', borderColor: 'darkblue', border: 'ridge' } : {}}>
              {p.lastName}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Birth Date</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')} style={(formatDate(p.birthDate) === formatDate(enteredBirthDate) || conditionalFormatting === false) ? { backgroundColor: 'turquoise', borderColor: 'darkblue', border: 'ridge' } : {}}>
              {formatDate(p.birthDate)}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Organization</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{p.orgDuration?.orgUnitName || <span>&nbsp;</span>}</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Effective Date</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{p.orgDuration?.effectiveDate ? formatDate(p.orgDuration.effectiveDate) : <span>&nbsp;</span>}</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Expiration Date</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{p.orgDuration?.expirationDate ? formatDate(p.orgDuration.expirationDate) : <span>&nbsp;</span>}</div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

const TeamAthleteRosterDupGrid = ({ actionLabel, persons, onSelected, enteredFirstName, enteredLastName, enteredBirthDate, conditionalFormatting }) => (
  <Fragment>
    <LargeGrid 
      actionLabel={actionLabel} 
      persons={persons} 
      onSelected={onSelected} 
      enteredFirstName={enteredFirstName} 
      enteredLastName={enteredLastName} 
      enteredBirthDate={enteredBirthDate}
      conditionalFormatting={conditionalFormatting} />
    <SmallGrid 
      actionLabel={actionLabel} 
      persons={persons} 
      onSelected={onSelected} 
      enteredFirstName={enteredFirstName}
      enteredLastName={enteredLastName}
      enteredBirthDate={enteredBirthDate}
      conditionalFormatting={conditionalFormatting}  />
  </Fragment>
);

export default TeamAthleteRosterDupGrid;