import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  items: [],
  message: ''
};

const getOtherOrgOrgUnits = (state, setState) => {
  if (state.isLoading !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/OrgUnit/OtherOrganization/TeamName`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          //Sort data in A-Z order
          data.sort(function (a, b) {
            const textA = a.orgUnitName.toUpperCase();
            const textB = b.orgUnitName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          })
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            items: data.map((x) => { return { id: x.orgUnitId, name: `${x.orgUnitName} (${x.parentOrgUnitCode ? x.parentOrgUnitCode + '/' : ''}${x.orgUnitCode})` } }),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
}

const OtherOrgOrgUnitTeamNameData = { INITIAL_STATE, getOtherOrgOrgUnits };

export default OtherOrgOrgUnitTeamNameData;