import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getReportPeriodsByOrganizationIdData from './GetReportPeriodsByOrganizationIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  treeData: []
};

const ReportPeriodData = {
  INITIAL_STATE, getReportPeriodsByOrganizationIdData
};

export default ReportPeriodData;
