import React, { Fragment } from 'react';

import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ pool }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{pool.facilityPoolName && pool.facilityPoolName.trim() !== '' ? pool.facilityPoolName : <span>&nbsp;</span>}</span>&nbsp;
    </div>
    <div className={['row', global.SmallTableRowInfo].join(' ')}>
      <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Pool Name</div>
      <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>{pool.facilityPoolName || <span>&nbsp;</span>}</div>
      <div className={['col-xs-6 col-sm-6', global.SmallTableRowLabels].join(' ')}>Pool Dimensions</div>
      <div className={['col-xs-6 col-sm-6', global.SmallTableRowData].join(' ')}>{pool.poolDimensions ? pool.poolDimensions.dimensionName : <span>&nbsp;</span>}</div>
    </div>
  </div>
);

const GridRowDetailTable = ({ facility }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(facility.facilityPool) && facility.facilityPool.map((pool, i) =>
      <DetailTableRow key={i} pool={pool} />)}
  </div>
);

const GridRow = ({ index, orgUnitFacility, expandedId, onClick }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow} key={orgUnitFacility.facility?.facilityId > 0 ? orgUnitFacility.facility.facilityId : index}>
        <div className={global.SmallTableRowHead}>
          <span className="hidden-xs">{orgUnitFacility.facility?.facilityName}</span>&nbsp;
          <button className={global.IconButton}
            type="button"
            onClick={(e) => onClick(e, orgUnitFacility.facility?.facilityName)}>
            {expandedId === orgUnitFacility.facility?.facilityId ? <HideIcon /> : <ShowIcon />}
          </button>
        </div>
        <div className={['row', global.SmallTableRowInfo].join(' ')}>
          <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Facility Name</div>
          <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{orgUnitFacility.facility?.facilityName || <span>&nbsp;</span>}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>City</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{orgUnitFacility.facility?.city}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>State</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{orgUnitFacility.facility?.stateCode}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Zip Code</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{orgUnitFacility.facility?.postalCode}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Address</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{orgUnitFacility.facility?.address1}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Purpose</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{orgUnitFacility.trainingFacility === true ? 'Training' : 'Competition'}</div>
        </div>
      </div>
      {Array.isArray(orgUnitFacility.facility.facilityPool) && orgUnitFacility.facility.facilityPool.length > 0 && expandedId === orgUnitFacility.facility.facilityId &&
        <GridRowDetailTable facility={orgUnitFacility.facility} />}
    </Fragment>
  );
};

const SmallTeamFacilityReadGrid = ({ state, expandedId, onClick }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
      {state.isObjLoaded !== true
        ? (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading... </div>
              : <div className={global.SmallTableRowLabels}>No Facilities</div>}
          </div>
        </div>
        ) : Array.isArray(state.objData.orgUnitFacility) && state.objData.orgUnitFacility.length > 0
          ? state.objData.orgUnitFacility.map((orgUnitFacility, i) =>
            <GridRow key={i} index={i} orgUnitFacility={orgUnitFacility} onClick={onClick} expandedId={expandedId} />)
          : (
            < div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>
                &nbsp;
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Facilities</div>
              </div>
            </div>)
      }
    </div>
  </Fragment >
);

export default SmallTeamFacilityReadGrid;