import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './TeamFacilityDetailsValidation';

import NavLinks from '../NavLinks';

import useTeamData from '../../../state/team/UseTeamData';
import useTeamFacilityLinksData from '../../../state/teamFacilityLinks/UseTeamsFacilityLinksData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useTeamFacilityDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    tryRedirect: false, isFacilityInTeamObj: false, facilityId: Constants.DEFAULT_ID, isNewFacility: false
  });
  const { teamState } = useTeamData();
  const { teamFacilityLinksState, putTeamFacilityLinks, getTeamFacilityLinks } = useTeamFacilityLinksData();

  const {
    formState,
    errorState,
    setFormState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    isDirty,
    isSubmitting
  } = useForm(getInitialTeamFacilityState, submitFormCallback, validate);

  function submitFormCallback() {
    putTeamFacilityLinks(teamState.objData.orgUnitId, formState.orgUnitFacilityId, constructOrgUnitObjectForUpdate());
    setState({ ...state, tryRedirect: true });
  };

  function constructOrgUnitObjectForUpdate() {
    let obj = {
      orgUnitFacilityId: formState.orgUnitFacilityId,
      orgUnitId: formState.orgUnitId,
      facilityId: formState.facilityId,
      effectiveDate: null,
      expirationDate: null,
      facility: undefined,
      clubFacilityContactName: formState.clubFacilityContactName.trim(),
      clubFacilityContactEmailAddress: formState.clubFacilityContactEmailAddress.trim(),
      clubFacilityContactPhoneNumber: formState.clubFacilityContactPhoneNumber,
      facilityContactName: formState.contactName.trim(),
      facilityContactEmailAddress: formState.contactEmailAddress.trim(),
      facilityContactPhoneNumber: formState.contactPhoneNumber,
      trainingFacility: formState.trainingFacility
    };

    return obj;
  };

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
    if (isDirty === false) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onCancelGoToTeamFacility = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.TEAM_FACILITY);
  };

  useEffect(() => {
    if (location.state && location.state.facilityId && location.state.isNewFacility === true
    ) {
      getTeamFacilityLinks(teamState.objData.orgUnitId);
      setState({ ...state, isFacilityInTeamObj: true, facilityId: location.state.facilityId, isNewFacility: true });
    }
    else if (location.state && location.state.facilityId && location.state.isNewFacility === undefined) {
      setState({ ...state, isFacilityInTeamObj: true, facilityId: location.state.facilityId, isNewFacility: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (teamFacilityLinksState.isArrayLoaded === true && teamFacilityLinksState.isSaving === false) {
      if (state.isFacilityInTeamObj === true && state.facilityId > 0) {
        const selectedFacility = teamFacilityLinksState.arrayData.find((facility) =>
          facility.facilityId === state.facilityId);

        setFormState({
          ...formState,
          facilityId: selectedFacility.facilityId || Constants.DEFAULT_ID,
          facilityName: selectedFacility.facility.facilityName || '',
          address1: selectedFacility.facility.address1 || '',
          address2: selectedFacility.facility.address2 || '',
          city: selectedFacility.facility.city || '',
          stateCode: selectedFacility.facility.stateCode || '',
          postalCode: selectedFacility.facility.postalCode || '',
          contactName: selectedFacility.facility.contactName || '',
          contactEmailAddress: selectedFacility.facility.contactEmailAddress || '',
          contactPhoneNumber: selectedFacility.facility.contactPhoneNumber || '',
          hostMeet: selectedFacility.facility.hostMeet || '',
          clubFacilityContactName: selectedFacility.clubFacilityContactName || '',
          clubFacilityContactEmailAddress: selectedFacility.clubFacilityContactEmailAddress || '',
          clubFacilityContactPhoneNumber: selectedFacility.clubFacilityContactPhoneNumber || '',
          orgUnitFacilityId: selectedFacility.orgUnitFacilityId || Constants.DEFAULT_ID,
          orgUnitId: selectedFacility.orgUnitId || '',
          trainingFacility: selectedFacility.trainingFacility !== undefined ? selectedFacility.trainingFacility : ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamFacilityLinksState, state]);

  useEffect(() => {
    if (teamFacilityLinksState.isArrayLoaded === true && teamFacilityLinksState.isSaving === false && state.tryRedirect === true
      && isSubmitting === false && Object.keys(errorState).length === 0) {
      if (state.isNewFacility === true) {
        navigate(NavLinks.TEAM_FACILITY_POOL_DETAIL, { state: { facilityId: state.facilityId } });
      }
      else if (state.isNewFacility === false) {
        navigate(NavLinks.TEAM_FACILITY, { state: { hasNewFacilityDetails: true } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamFacilityLinksState, state, isSubmitting, errorState]);

  return {
    teamFacilityLinksState,
    formState,
    errorState,
    onSaveClicked,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onCancelGoToTeamFacility
  };

  function getInitialTeamFacilityState() {
    return {
      facilityId: Constants.DEFAULT_ID,
      facilityName: '',
      address1: '',
      address2: '',
      city: '',
      stateCode: '',
      postalCode: '',
      contactName: '',
      contactEmailAddress: '',
      contactPhoneNumber: '',
      hostMeet: false,
      clubFacilityContactName: '',
      clubFacilityContactEmailAddress: '',
      clubFacilityContactPhoneNumber: '',
      trainingFacility: ''
    };
  };
};

export default useTeamFacilityDetails;