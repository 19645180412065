import React, { Fragment } from 'react';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeDetailTableRow = ({ personId, role, onDelete }) => (
  <tr>
    <td>{role.orgRoleName}</td>
    <td>{formatDate(role.effectiveDate)}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, personId, role.personOrgRoleDurationId)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const LargeDetailTable = ({ staff, onDelete }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Role</th>
        <th>Effective Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {staff.personOrgRole.map((role, i) => <LargeDetailTableRow personId={staff.personId} role={role} onDelete={onDelete} key={i} />)}
    </tbody>
  </table>
);

const LargeTableRow = ({ staff, showMemberId, onEdit, onDelete, onClick, expandedId }) => (
  <Fragment>
    <tr>
      <td>{staff.firstName} {staff.lastName}</td>
      <td>{formatPhoneNumber(staff.phoneNumber)}</td>
      <td>{staff.email}</td>
      {showMemberId === true &&
        <td>{staff.memberId}</td>
      }
      <td>{staff.isInGoodStanding === true ? 'Yes' : 'No'}</td>
      <td>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, staff.personId)}>
          <EditIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onClick(e, staff.personId)}>
          {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && expandedId === staff.personId &&
      <tr className={global.Expanded}>
        <td colSpan={showMemberId === true ? '6' : '5'}>
          <LargeDetailTable staff={staff} onDelete={onDelete} />
        </td>
      </tr>}
  </Fragment>
);

const LargeGrid = ({ state, showMemberId, onEdit, onDelete, expandedId, onClick }) => (
  <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
    <thead>
      <tr>
        <th>Team Staff Name</th>
        <th>Phone Number</th>
        <th>Email</th>
        {showMemberId === true &&
          <th>Member Id</th>
        }
        <th>Is in good standing?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true
        ? (
          <tr>
            {state.isArrayLoading === true ?
              <td colSpan={showMemberId === true ? '6' : '5'}>Loading...</td>
              : <td colSpan={showMemberId === true ? '6' : '5'}>No Staff</td>
            }
          </tr>
        ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
          ?
          state.arrayData.map((staff, i) => <LargeTableRow staff={staff} key={i} showMemberId={showMemberId} onEdit={onEdit} onDelete={onDelete}
            expandedId={expandedId} onClick={onClick} />)
          : (
            <tr>
              <td colSpan={showMemberId === true ? '6' : '5'}>No Staff</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

const SmallDetailTableRow = ({ personId, role, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}><span className='hidden-xs'>{role.orgRoleName}</span>&nbsp;
      <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, personId, role.personOrgRoleDurationId)}>
        <DeleteIcon />
      </button>
    </div>
    <div className={['row', global.SmallTableRowInfo].join(' ')}>
      <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Role</div>
      <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>{role.orgRoleName}</div>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Effective Date</div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>{formatDate(role.effectiveDate)}</div>
    </div>
  </div>
);

const SmallDetailTable = ({ staff, onDelete }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(staff.personOrgRole) && staff.personOrgRole.map((role, i) =>
      <SmallDetailTableRow key={i} personId={staff.personId} role={role} onDelete={onDelete} />)}
  </div>
);

const SmallGridRow = ({ staff, showMemberId, onEdit, onDelete, expandedId, onClick }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{staff.firstName} {staff.lastName}</span>&nbsp;
        <button
          className={global.IconButton}
          type="button"
          onClick={(e) => onClick(e, staff.personId)}>
          {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
        </button>
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={(e) => onEdit(e, staff.personId)}>
          <EditIcon />
        </button>
      </div>
      <div className={['row', global.SmallTableRowInfo].join(' ')}>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Staff Name</div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{staff.firstName} {staff.lastName}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Phone Number</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatPhoneNumber(staff.phoneNumber) || <span>&nbsp;</span>}</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Email</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{staff.email || <span>&nbsp;</span>}</div>
        {showMemberId === true &&
          <Fragment>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Member Id</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{staff.memberId || <span>&nbsp;</span>}</div>
          </Fragment>
        }
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Is In Good Standing?</div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{staff.isInGoodStanding === true ? 'Yes' : 'No'}</div>
      </div>
    </div>
    {Array.isArray(staff.personOrgRole) && staff.personOrgRole.length > 0 && expandedId === staff.personId &&
      <SmallDetailTable staff={staff} onDelete={onDelete} />}
  </Fragment>
);

const SmallGrid = ({ state, showMemberId, onEdit, onDelete, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Staff</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((staff, i) =>
          <SmallGridRow key={i} staff={staff} showMemberId={showMemberId} onEdit={onEdit} onDelete={onDelete} expandedId={expandedId} onClick={onClick} />) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Staff</div>
            </div>
          </div>
        )
    }
  </div>
);

const TeamStaffWriteGrid = ({ state, showMemberId = false, onDelete, onEdit }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <LargeGrid state={state} showMemberId={showMemberId} onDelete={onDelete} onEdit={onEdit} expandedId={expandedId} onClick={onClick} />
      <SmallGrid state={state} showMemberId={showMemberId} onDelete={onDelete} onEdit={onEdit} expandedId={expandedId} onClick={onClick} />
    </Fragment>
  );
};

export default TeamStaffWriteGrid;