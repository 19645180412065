import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './MeetAddValidation';

import NavLinks from '../../NavLinks';

import useTeamData from '../../../../state/team/UseTeamData';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useMeetClassificationData from '../../../../../common/state/meet/meetClassification/UseMeetClassificationData';
import useCourseData from '../../../../../common/state/course/UseCourseData';
import useMeetSoftwareData from '../../../../../common/state/meet/meetSoftware/UseMeetSoftwareData';
import useTeamFacilityLinksNoContextData from '../../../../../common/state/teamFacilityLinks/UseTeamFacilityLinksNoContextData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const useMeetAdd = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(getInitialState);
  const [courseOptionsState, setCourseOptionsState] = useState([]);
  const { teamState } = useTeamData();
  const { meetState, postMeet } = useMeetData();
  const { meetClassificationState } = useMeetClassificationData();
  const { meetSoftwareState } = useMeetSoftwareData();
  const { courseState, getCourses } = useCourseData();
  const { teamFacilityLinksState, getTeamFacilityLinks } = useTeamFacilityLinksNoContextData();
  const [facilityOptionsState, setFacilityOptionsState] = useState([{ id: Constants.DEFAULT_ID, name: '--' }]);

  const { errorState, formState, handleSubmit, updateFormState, setFormState, onValueTextPairChanged, setIsDirty } = 
    useForm(getInitialFormState, submitFormCallback, validate);

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.TEAM_MEET);
  };

  const onMeetClassificationChanged = (newValue, newValueLabel) => {
    setFormState({
      ...formState,
      meetClassificationId: newValue,
      meetClassificationName: newValueLabel,
      meetTypeId: Constants.DEFAULT_ID,
      meetTypeName: ''
    });
    setIsDirty(true);
  };

  useEffect(() => {
    if (courseState.isLoaded === false && courseState.isLoading === false) {
      getCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseState]);

  useEffect(() => {
    if (courseState.isLoaded === true) {
      const courseOptions = [];

      for (const course of courseState.data) {
        if (course.courseId > 0) {
          courseOptions.push({ id: course.courseId, name: course.courseCode });
        }
      }

      setCourseOptionsState(courseOptions);
    }
  }, [courseState]);

  useEffect(() => {
    if (teamFacilityLinksState.isArrayLoading === false && teamFacilityLinksState.isArrayLoaded === true) {
      setFacilityOptionsState(getFacilityAsOptions(teamFacilityLinksState.arrayData || []));
    }
  }, [teamFacilityLinksState]);

  useEffect(() => {
    if (teamState.isObjLoading === false && teamState.isObjLoaded === true) {
      getTeamFacilityLinks(teamState.objData.orgUnitId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamState]);

  useEffect(() => {
    let selectedOrgUnitFacility;
    let selectedFacility;

    if (formState.facilityId > 0) {
      selectedOrgUnitFacility = teamFacilityLinksState.arrayData.find(x => x.facilityId === formState.facilityId);
    }
    if (selectedOrgUnitFacility) {
      selectedFacility = selectedOrgUnitFacility.facility;
    }

    setState({
      ...state,
      facilityCity: selectedFacility?.city || '',
      facilityState: selectedFacility?.stateCode || '',
      facilityCountry: selectedFacility?.country || '',
      facilityAltitude: selectedFacility?.altitude !== null && selectedFacility?.altitude >= 0
        ? selectedFacility?.altitude : ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.facilityId]);

  useEffect(() => {
    if (state.tryRedirect === true) {
      if (meetState.isSaving === false && meetState.isSaved === true) {
        navigate(NavLinks.TEAM_MEET_ADD_SESSIONS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, state]);

  function submitFormCallback(formState) {
    if (meetState.isSaving !== true) {
      postMeet(createMeetObject());

      setState({
        ...state,
        tryRedirect: true
      });
    }
  };

  function createMeetObject() {
    return {
      meetClassificationId: formState.meetClassification[0].id,
      meetName: formState.meetName.trim(),
      startDate: formState.startDate,
      endDate: formState.endDate,
      hostOrgUnitId: teamState.objData.orgUnitId,
      selectedSoftware: formState.meetSoftwareName,
      meetSoftwareId: formState.meetSoftwareId > 0 ? formState.meetSoftwareId : null,
      facilityId: formState.facilityId > 0 ? formState.facilityId : null,
      meetAbbreviation: null,
      meetCourse: formState.meetCourse.map(course => { return { courseId: course.id }; }),
      isUsasMeet: false,
      isFinaMeet: false,
      isNcaaMeet: false,
      isInternationalSwimLeagueMeet: false
    };
  };

  function getFacilityAsOptions(facilityArray) {
    const facilityOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
    for (const facility of facilityArray) {
      if (facility.facility && facility.facility.hostMeet === true) {
        facilityOptions.push({ id: facility.facility.facilityId, name: facility.facility.facilityName });
      }
    }

    return facilityOptions;
  };

  function getInitialState() {
    return {
      tryRedirect: false,
      facilityData: [],
      facilityCity: '',
      facilityState: '',
      facilityCountry: '',
      facilityAltitude: ''
    };
  };

  function getInitialFormState() {
    return {
      meetName: '',
      meetClassification: [],
      meetAbbreviation: '',
      meetCourse: [],
      meetClassificationId: Constants.DEFAULT_ID,
      meetClassificationName: '',
      facilityId: Constants.DEFAULT_ID,
      facilityName: '',
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING,
      meetSoftwareId: Constants.DEFAULT_ID,
      meetSoftwareName: ''
    };
  };

  return {
    teamState,
    meetState,
    state,
    formState,
    errorState,
    courseState,
    courseOptionsState,
    meetClassificationState,
    meetSoftwareState,
    facilityOptionsState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    onMeetClassificationChanged,
    Constants
  };
};

export default useMeetAdd;