export const localValidate = (formState) => {
  let errors = {};

  if (formState.teamOrgUnitId < 0) {
    errors.teamOrgUnitId = 'Team is required';
  }

  return errors;
};

const OtherTeamAthleteRosterValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OtherTeamAthleteRosterValidation;