import React, { Fragment } from 'react';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, gridState, organizationState }) => (
  <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
    <thead>
      <tr>
        <th>Athlete Name</th>
        <th>Member Id</th>
        <th>Age</th>
        <th>Competition Category</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        {organizationState.isNCAA === true ?
          <th>Year of Eligibility</th> : <Fragment />}
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true
        ? (
          <tr>
            {organizationState.isNCAA === true ?
              <Fragment>
                {state.isArrayLoading ?
                  <td colSpan="6">Loading...</td>
                  : <td colSpan="6">No Athletes</td>
                }
              </Fragment>
              :
              <Fragment>
                {state.isArrayLoading ?
                  <td colSpan="5">Loading...</td>
                  : <td colSpan="5">No Athletes</td>
                }
              </Fragment>}
          </tr>
        ) : Array.isArray(gridState) && gridState.length > 0
          ? gridState.map((athlete, i) => {
            // const athleteName = `${athlete.lastName}, ${athlete.preferredName || athlete.firstName}`;
            return (
            <tr key={i}>
              <td>{athlete.preferredName !== '' ? athlete.preferredName : athlete?.firstName} {athlete.lastName}</td>
              <td>{athlete.memberId}</td>
              <td>{athlete.age}</td>
              <td>{athlete.competitionGenderTypeName}</td>
              <td>{formatDate(athlete.effectiveDate)}</td>
              <td>{formatDate(athlete.expirationDate)}</td>
              {organizationState.isNCAA === true ?
                <td>{athlete.yearOfEligibility ? athlete.yearOfEligibility : ''}</td>
                : <Fragment />}
            </tr>
            );}
        ) : (
            <Fragment>
              {organizationState.isNCAA === true ?
                <tr>
                  <td colSpan="6">No Athletes</td>
                </tr>
                :
                <tr>
                  <td colSpan="5">No Athletes</td>
                </tr>
              }
            </Fragment>
          )}
    </tbody>
  </table>
);

const SmallGrid = ({ state, gridState, organizationState }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Athletes</div>}
          </div>
        </div>)
      : Array.isArray(gridState) && gridState.length > 0
        ? gridState.map((athlete, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{athlete.preferredName !== '' ? athlete.preferredName : athlete?.firstName} {athlete.lastName}</span>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className="row">
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Athlete Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{athlete.preferredName !== '' ? athlete.preferredName : athlete?.firstName} {athlete.lastName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Member Id</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{athlete.memberId}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Age</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{athlete.age}</div>
                <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{athlete.competitionGenderTypeName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Effective Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatDate(athlete.effectiveDate)}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Expiration Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatDate(athlete.expirationDate)}</div>
                {organizationState.isNCAA === true ?
                  <Fragment>
                    <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Year of Eligibility</div>
                    <div className={['col-xs-6 col-sm-3', global.SmallTableRowData, global.Multiline].join(' ')}>{athlete.yearOfEligibility ? athlete.yearOfEligibility : <span>&nbsp;</span>}</div>
                  </Fragment>
                  : <Fragment />}
              </div>
            </div>
          </div>))
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Athletes</div>
            </div>
          </div>
        )}
  </div >
);

const TeamAthleteRosterReadGrid = ({ state, gridState, organizationState }) => (
  <Fragment>
    <LargeGrid state={state} gridState={gridState} organizationState={organizationState} />
    <SmallGrid state={state} gridState={gridState} organizationState={organizationState} />
  </Fragment>
);

export default TeamAthleteRosterReadGrid;