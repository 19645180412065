import { useEffect, useState } from "react";
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from "../NavLinks";

import useTeamData from '../../../state/team/UseTeamData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgUnitStaffData from "../../../../common/state/orgUnitStaff/UseOrgUnitStaffData";

import Constants from "../../../../common/utils/Constants";

const useTeamStaff = () => {
  const navigate = useNavigate();
  const { teamState } = useTeamData();
  const { environmentVariableState, NCAA_STAFF_ROLE_GROUP_ID, FINA_STAFF_ROLE_GROUP_ID,
    NCAA_ORGANIZATION_ID, ISL_ORGANIZATION_ID, ISL_STAFF_ROLE_GROUP_ID, SWIMATHON_STAFF_ROLE_GROUP_ID,
    SWIM_A_THON_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { orgUnitStaffState, getOrgUnitStaff, confirmOrgUnitStaffStateSaved, deleteOrgUnitStaff } = useOrgUnitStaffData();
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState);

  const onAddStaff = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.TEAM_STAFF_DETAIL, { state: { personId: '' } });
  };

  const onEditStaff = (e, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    navigate(NavLinks.TEAM_STAFF_DETAIL, { state: { personId } });
  };

  const onDeleteRole = (e, personId, personOrgRoleDurationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    const targetPerson = orgUnitStaffState.arrayData.find(x => x.personId === personId);

    if (targetPerson && Array.isArray(targetPerson.personOrgRole)) {
      if (targetPerson.personOrgRole.length === 1 && targetPerson.personOrgRole[0].personOrgRoleDurationId === personOrgRoleDurationId) {
        setDeleteModalState({
          ...deleteModalState,
          personOrgRoleDurationId,
          personName: `${targetPerson.firstName} ${targetPerson.lastName}`,
          displayModal: true
        });
      } else {
        deleteOrgUnitStaff(personOrgRoleDurationId);
      }
    }
  };

  const onModalDeleteClicked = () => {
    deleteOrgUnitStaff(deleteModalState.personOrgRoleDurationId);
    setDeleteModalState(getInitialDeleteModalState);
  };

  const onModalCancelClicked = () => {
    setDeleteModalState(getInitialDeleteModalState);
  };

  useEffect(() => {
    if (teamState.isObjLoaded === true && environmentVariableState.isLoaded === true) {
      if (orgUnitStaffState.isSaved === false) {
        if (teamState.objData?.orgLevel?.organizationId === NCAA_ORGANIZATION_ID) {
          getOrgUnitStaff(teamState.objData.orgUnitId, NCAA_STAFF_ROLE_GROUP_ID);
        } else if (teamState.objData?.orgLevel?.organizationId === ISL_ORGANIZATION_ID) {
          getOrgUnitStaff(teamState.objData.orgUnitId, ISL_STAFF_ROLE_GROUP_ID);
        } else if (teamState.objData?.orgLevel?.organizationId === SWIM_A_THON_ORGANIZATION_ID) {
          getOrgUnitStaff(teamState.objData.orgUnitId, SWIMATHON_STAFF_ROLE_GROUP_ID);
        } else {
          getOrgUnitStaff(teamState.objData.orgUnitId, FINA_STAFF_ROLE_GROUP_ID);
        }
      } else {
        confirmOrgUnitStaffStateSaved();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [orgUnitStaffState.isSaved, teamState.isObjLoaded, environmentVariableState]);

  function getInitialDeleteModalState() {
    return {
      personOrgRoleDurationId: Constants.DEFAULT_ID,
      personName: '',
      displayModal: false
    };
  };

  return {
    teamState,
    orgUnitStaffState,
    deleteModalState,
    showMemberId: teamState.objData?.orgLevel?.organizationId === NCAA_ORGANIZATION_ID,
    onDeleteRole,
    onAddStaff,
    onEditStaff,
    onModalDeleteClicked,
    onModalCancelClicked
  };
};

export default useTeamStaff;