import React, { Fragment } from 'react';

import useTeamMeet from './UseTeamMeet';

import TeamMeetGridRead from './TeamMeetGridRead';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReportPeriodTreeView from '../../../../common/components/tree/reportPeriodTree/ReportPeriodTreeView';

import Constants from '../../../../common/utils/Constants';

const TeamMeetReadOnly = () => {
  const { teamMeetState, filterState, setFilterState, teamState } = useTeamMeet();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Team Meets</Headings.H3>
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12 col-sm-6">
          <ReportPeriodTreeView
            label="Report Period"
            name="reportPeriod"
            value={filterState.reportPeriod}
            selectableLeavesOnly={false}
            singleSelect={true}
            onChange={(newValue) => {
              setFilterState({ ...filterState, reportPeriod: newValue });
            }}
            organizationId={teamState.objData?.orgLevel?.organizationId || Constants.DEFAULT_ID}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <TeamMeetGridRead state={teamMeetState} gridState={teamMeetState.arrayData} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={teamMeetState.isArrayLoading} />
    </Fragment>
  );
};

export default TeamMeetReadOnly;