import React, { Fragment } from 'react';

import OtherOrgOrgUnitCombobox from '../comboboxes/OtherOrgOrgUnitCombobox';

import Input from '../../../common/components/inputs/Input';
import Dropdown from '../../../common/components/dropdowns/Dropdown';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';
import TreeView from '../../../common/components/tree/TreeView';

import global from '../../../common/components/GlobalStyle.module.css';

const SearchTeamForm = ({ handleSubmit, otherOrgOrgUnitState, formState, errorState, onFormValueChanged, onValueTextPairChanged, clearForm, sortByOptions,
  onCancelClicked, onAddClicked }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <TreeView
          label="Organization"
          name="orgUnit"
          value={formState.orgUnit}
          options={otherOrgOrgUnitState.treeData}
          selectableLeavesOnly={false}
          singleSelect={true}
          error={errorState.orgUnit}
          message={errorState.orgUnit}
          isLoading={otherOrgOrgUnitState.isArrayLoading}
          onChange={(newValue) => { onFormValueChanged('orgUnit', newValue) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <OtherOrgOrgUnitCombobox
          label="Team Name (Parent Code/Team Code)"
          name="teamOrgUnitName"
          valueToMatch={formState.teamOrgUnitName}
          error={errorState.teamOrgUnitId}
          message={errorState.teamOrgUnitId}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'teamOrgUnitId', newValueLabel, 'teamOrgUnitName', e); }}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Coach Last Name"
          name="coachLastName"
          value={formState.coachLastName}
          error={errorState.coachLastName}
          message={errorState.coachLastName}
          onChange={(value) => { onFormValueChanged('coachLastName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Sort Results By"
          name="sortById"
          options={sortByOptions}
          value={formState.sortById}
          error={errorState.sortById}
          message={errorState.sortById}
          showEmptySelection={false}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
          }} />
      </div>
    </div>
    {(errorState.filter || errorState.errorOnSubmitAction) &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>}
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Search for Team</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>&nbsp;
        {onCancelClicked !== undefined ? <SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton> : <Fragment />}
        <ActionIntraPageButton type="button" onClick={() => onAddClicked()}>Add Team</ActionIntraPageButton>
      </div>
    </div>
  </form >
)

export default SearchTeamForm;