import { useState } from 'react';

import OtherOrgOrgUnitChangeData from './OtherOrgOrgUnitChangeData';

const useOtherOrgOrgUnitChangeData = () => {
  const [otherOrgOrgUnitChangeState, setOtherOrgOrgUnitChangeState] = useState(OtherOrgOrgUnitChangeData.INITIAL_STATE);

  const putOtherOrgOrgUnitChange = (orgUnitId, orgUnitObj) => 
    OtherOrgOrgUnitChangeData.putOtherOrgOrgUnitChangeData(orgUnitId, orgUnitObj, otherOrgOrgUnitChangeState, setOtherOrgOrgUnitChangeState);

  return {
    otherOrgOrgUnitChangeState,
    putOtherOrgOrgUnitChange
  };
};

export default useOtherOrgOrgUnitChangeData;