import React, { Fragment } from 'react';

import useTeamFacility from './UseTeamFacility';

import TeamFacilityReadGrid from './TeamFacilityReadGrid';

import Headings from '../../../../common/components/headings/Headings';

const TeamFacilityReadOnly = () => {
  const { teamState } = useTeamFacility();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Team Facilities</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <TeamFacilityReadGrid state={teamState} />
        </div>
      </div>
    </Fragment>
  );
};

export default TeamFacilityReadOnly;