import React from 'react';

import useTeamStaffRoleMultiselect from './UseTeamStaffRoleMultiselect';

import MultiSelect from '../../../common/components/multiSelect/MultiSelect';

import global from '../../../common/components/GlobalStyle.module.css';

const TeamStaffRoleMultiselect = ({ label, name, value, error, message, onChange, roleGroupId }) => {
  const { teamStaffRolesState } = useTeamStaffRoleMultiselect(roleGroupId);

  return teamStaffRolesState.message
    ? <span className={global.LoadingMsg}>{teamStaffRolesState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={teamStaffRolesState.options}
        error={error}
        message={message}
        isLoading={teamStaffRolesState.isArrayLoading}
        onChange={onChange} />
    );
};

export default TeamStaffRoleMultiselect;