import React from 'react';

import OtherOrgUnitContextProvider from './state/otherOrgOrgUnit/OtherOrgUnitContextProvider';

import TeamStaffRolesContextProvider from './state/teamStaffRoles/TeamStaffRolesContextProvider';

import CourseContextProvider from '../common/state/course/CourseContextProvider';
import MeetClassificationContextProvider from '../common/state/meet/meetClassification/MeetClassificationContextProvider';
import MeetClassificationTypeContextProvider from '../common/state/meet/meetClassificationType/MeetClassificationTypeContextProvider';
import MeetSoftwareContextProvider from '../common/state/meet/meetSoftware/MeetSoftwareContextProvider';
import ClubAffiliationTypeContextProvider from '../common/state/clubAffiliationType/ClubAffiliationTypeContextProvider';
import ReportPeriodFlatContextProvider from '../common/state/reportPeriodFlat/ReportPeriodFlatContextProvider';
import PoolDimensionsContextProvider from '../common/state/poolDimensions/PoolDimensionsContextProvider';
import ReportPeriodContextProvider from '../common/state/reportPeriod/ReportPeriodContextProvider';
import CompetitionGenderTypeContextProvider from '../common/state/competitionGender/CompetitionGenderTypeContextProvider';

const AppStateContextProviders = ({ children }) => (
  <OtherOrgUnitContextProvider>
    <PoolDimensionsContextProvider>
      <CourseContextProvider>
        <MeetClassificationContextProvider>
          <MeetClassificationTypeContextProvider>
            <MeetSoftwareContextProvider>
              <ClubAffiliationTypeContextProvider>
                <ReportPeriodContextProvider>
                  <ReportPeriodFlatContextProvider>
                    <TeamStaffRolesContextProvider>
                      <CompetitionGenderTypeContextProvider>
                        {children}
                      </CompetitionGenderTypeContextProvider>
                    </TeamStaffRolesContextProvider>
                  </ReportPeriodFlatContextProvider>
                </ReportPeriodContextProvider>
              </ClubAffiliationTypeContextProvider>
            </MeetSoftwareContextProvider>
          </MeetClassificationTypeContextProvider>
        </MeetClassificationContextProvider>
      </CourseContextProvider>
    </PoolDimensionsContextProvider>
  </OtherOrgUnitContextProvider>
);

export default AppStateContextProviders;