import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import { formatDate } from '../../../../../common/utils/DateFunctions';

const useMeetUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [meetHeaderState, setMeetHeaderState] = useState(getInitialMeetHeaderState);

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.TEAM_MEET);
  };

  useEffect(() => {
    if (location.state?.meet) {
      setMeetHeaderState({
        ...meetHeaderState,
        meetName: location.state.meet.meetName,
        meetDates: formatDate(location.state.meet.startDate)
          + ' - ' + formatDate(location.state.meet.endDate),
        meetHost: location.state.meet.orgUnitName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInitialMeetHeaderState() {
    return {
      meetName: '',
      meetDates: '',
      meetHost: ''
    };
  }

  return {
    meetId: location.state?.meet?.meetId || -1,
    meetHeaderState,
    onBackClicked,
    meetStartDate: location.state.meet.startDate,
    meetEndDate: location.state.meet.endDate
  };
};

export default useMeetUpload;