import {BASIC_INITIAL_STATE} from '../../../common/utils/HttpHelper';

import putOtherOrgOrgUnitChangeData from './PutOtherOrgOrgUnitChangeData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE, items: []
};

const OtherOrgOrgUnitChangeData = {
  INITIAL_STATE, putOtherOrgOrgUnitChangeData
};

export default OtherOrgOrgUnitChangeData;