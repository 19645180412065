import { useEffect, useState } from "react";
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from '../TeamAthleteDetailValidation';

import NavLinks from "../../NavLinks";

import useTeamData from '../../../../state/team/UseTeamData';

import useOrgUnitAthleteData from "../../../../../common/state/orgUnitAthlete/UseOrgUnitAthleteData";
import usePersonData from "../../../../../common/state/person/UsePersonData";
import useOrgRoleStatusData from '../../../../../common/state/orgRoleStatus/UseOrgRoleStatusData';
import useEnvironmentVariableData from "../../../../../common/state/environmentVariable/UseEnvironmentVariableData";
import useReportPeriodFlatData from "../../../../../common/state/reportPeriodFlat/UseReportPeriodFlatData";
import useCompetitionGenderTypeData from "../../../../../common/state/competitionGender/UseCompetitionGenderTypeData";

import useForm from '../../../../../common/utils/UseForm';
import Constants from "../../../../../common/utils/Constants";
import { formatDate } from "../../../../../common/utils/DateFunctions";

const yearOfEligibilityOptions = [
  { id: Constants.DEFAULT_ID, name: '--', value: '' },
  { id: 1, name: 'Freshman', value: 'Freshman' },
  { id: 2, name: 'Sophomore', value: 'Sophomore' },
  { id: 3, name: 'Junior', value: 'Junior' },
  { id: 4, name: 'Senior', value: 'Senior' },
  { id: 5, name: 'Grad Student', value: 'Grad Student' }];

let ncaaOrgRoleOptions = [];

const useTeamAthleteWriteDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({ isNCAA: false, isEdit: false, isSet: false });
  const [dateState, setDateState] = useState({ effectiveDateISL: Constants.BLANK_DATE_STRING, expirationDateISL: Constants.BLANK_DATE_STRING });
  const [dupGridState, setDupGridState] = useState([]);
  const [newGridState, setNewGridState] = useState([]);
  const { teamState } = useTeamData();
  const { orgUnitAthleteState, postOrgUnitAthlete, putNcaaAthlete, resetOrgUnitAthleteState } = useOrgUnitAthleteData();
  const { personState, getPotentialPersonDupes, postBasePerson } = usePersonData();
  const { orgRoleStatusState, getOrgRoleStatus } = useOrgRoleStatusData();
  const { reportPeriodFlatState, getReportPeriodsFlatByOrganizationId } = useReportPeriodFlatData();
  const { competitionGenderState } = useCompetitionGenderTypeData();
  //TODO orgRoles as env variables, could be a problem 
  const { environmentVariableState, FINA_ORGANIZATION_ID, FINA_ORGANIZATION_NAME, FINA_ATHLETE_ORG_ROLE_ID,
    NCAA_ORGANIZATION_ID, NCAA_ORGANIZATION_NAME, NCAA_ATHLETE_ORG_ROLE_ID, NCAA_SEASON_START_MONTH_DAY,
    NCAA_SEASON_END_MONTH_DAY, ISL_ORGANIZATION_ID, ISL_ORGANIZATION_NAME, ISL_ATHLETE_ORG_ROLE_ID, NCAA_DIVER_ORG_ROLE_ID } = useEnvironmentVariableData();
  const { handleSubmit, updateFormState, onValueTextPairChanged, formState, errorState, isDirty,
    setFormState, setIsDirty } = useForm(getFormInitialState(), submitFormCallback, validate);

  const getOrgValues = () => {
    const today = new Date();
    const month = today.getMonth();
    const date = today.getDate();
    const year = today.getFullYear();
    let effectiveDate;
    let expirationDate;

    switch (teamState.objData?.orgLevel?.organizationId) {
      case FINA_ORGANIZATION_ID:
        return {
          organizationId: teamState.objData?.orgLevel?.organizationId || Constants.DEFAULT_ID,
          organizationName: FINA_ORGANIZATION_NAME,
          isNcaa: false,
          orgRoleId: FINA_ATHLETE_ORG_ROLE_ID,
          effectiveDate: formState.birthDate || Constants.BLANK_DATE_STRING,
          expirationDate: Constants.DEFAULT_MAX_DATE
        };
      case NCAA_ORGANIZATION_ID:
        effectiveDate = month >= 3 && month <= 7
          ? `${NCAA_SEASON_START_MONTH_DAY}/${(year).toString()}`
          : `${(month + 1).toString()}/${date.toString()}/${year.toString()}`;
        expirationDate = month >= 0 && month <= 2
          ? `${NCAA_SEASON_END_MONTH_DAY}/${(year).toString()}`
          : `${NCAA_SEASON_END_MONTH_DAY}/${(year + 1).toString()}`;
        return {
          organizationId: teamState.objData?.orgLevel?.organizationId || Constants.DEFAULT_ID,
          organizationName: NCAA_ORGANIZATION_NAME,
          isNcaa: true,
          orgRoleId: NCAA_ATHLETE_ORG_ROLE_ID,
          effectiveDate: month >= 3 && month <= 7 ? effectiveDate : formatDate(effectiveDate),
          expirationDate: expirationDate
        };
      case ISL_ORGANIZATION_ID:
        return {
          organizationId: formState.organizationId,
          organizationName: formState.organizationName || ISL_ORGANIZATION_NAME,
          orgRoleId: ISL_ATHLETE_ORG_ROLE_ID,
          effectiveDate: dateState.effectiveDateISL,
          expirationDate: dateState.expirationDateISL
        };
      default:
        return {
          organizationId: FINA_ORGANIZATION_ID,
          organizationName: FINA_ORGANIZATION_NAME,
          isNcaa: false,
          orgRoleId: FINA_ATHLETE_ORG_ROLE_ID,
          effectiveDate: formState.birthDate || Constants.BLANK_DATE_STRING,
          expirationDate: Constants.DEFAULT_MAX_DATE
        };
    }
  };
  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false && formState.personOrgRoleDurationId > 0 && formState.yearOfEligibilityId > 0) {
      navigate(NavLinks.TEAM_ATHLETEROSTER);
    }
    else {
      handleSubmit();
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.TEAM_ATHLETEROSTER);
  };

  const onModalCancelClicked = () => {
    setDupGridState([]);
    setNewGridState([]);
    setIsDirty(true);
  };

  const onModalContinueClicked = () => {
    let matchFound = false;

    for (const dup of dupGridState) {
      if (dup.isSelected === true) {
        postOrgUnitAthlete(createOrgUnitAthleteObj(dup.personId, formState, teamState));
        matchFound = true;
        break;
      }
    }

    if (matchFound === false) {
      const athlete = {
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        birthDate: formState.birthDate,
        competitionGenderTypeId: formState.competitionGenderTypeId,
        isDeleted: false
      };
      postBasePerson(athlete);
    }

    setDupGridState([]);
    setNewGridState([]);
  };

  const onSelectGridPerson = (personId) => {
    let newDupGridState = [];
    for (const dup of dupGridState) {
      newDupGridState.push({
        ...dup,
        isSelected: personId === dup.personId ? true : false
      });
    }

    setDupGridState(newDupGridState);
    setNewGridState([{
      ...newGridState[0],
      isSelected: personId ? false : true
    }]);
  };

  useEffect(() => {
    //Get flat report periods to figure out ISL effective and expiration dates
    if (Object.keys(teamState.objData).length > 0 && reportPeriodFlatState.isLoading !== true
      && teamState.objData?.orgLevel?.organizationId > 0
      && teamState.objData?.orgLevel?.organizationId !== reportPeriodFlatState.organizationId) {
      getReportPeriodsFlatByOrganizationId(teamState.objData.orgLevel.organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [teamState.objData, reportPeriodFlatState]);

  useEffect(() => {
    //Get ISL effective and expiration dates from report periods
    if (environmentVariableState.isLoaded === true && reportPeriodFlatState.isLoaded === true
      && reportPeriodFlatState.organizationId === ISL_ORGANIZATION_ID) {
      const today = new Date();
      const todayDate = formatDate(today);
      const todayFormatted = new Date(todayDate);
      const currentReportPeriods = [];
      const futureReportPeriods = [];
      let startDate;
      let endDate;
      for (let i = 0; i < reportPeriodFlatState.data.length; i++) {
        const selectedArrayData = reportPeriodFlatState.data[i];
        startDate = new Date(formatDate(selectedArrayData.startDate));
        endDate = new Date(formatDate(selectedArrayData.endDate));
        if (todayFormatted <= endDate && todayFormatted >= startDate) {
          currentReportPeriods.push(selectedArrayData);
        }
        if (todayFormatted < startDate) {
          futureReportPeriods.push(selectedArrayData);
        }
      }
      if (currentReportPeriods.length > 0) {
        const expirationDate = formatDate(currentReportPeriods[0].endDate);
        setDateState({ ...dateState, effectiveDateISL: todayDate, expirationDateISL: expirationDate });
      }
      else if (futureReportPeriods.length > 0) {
        const effectiveDate = formatDate(futureReportPeriods[0].startDate);
        const expirationDate = formatDate(futureReportPeriods[0].endDate);
        setDateState({ ...dateState, effectiveDateISL: effectiveDate, expirationDateISL: expirationDate });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [teamState.objData, reportPeriodFlatState, environmentVariableState]);

  useEffect(() => {
    //Get flat report periods to figure out ISL effective and expiration dates
    if (Object.keys(teamState.objData).length > 0 && reportPeriodFlatState.isLoading !== true
      && teamState.objData?.orgLevel?.organizationId > 0
      && teamState.objData?.orgLevel?.organizationId !== reportPeriodFlatState.organizationId) {
      getReportPeriodsFlatByOrganizationId(teamState.objData.orgLevel.organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [teamState.objData, reportPeriodFlatState]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && Object.keys(teamState.objData).length > 0) {
      const newState = {
        ...formState,
        ...(getOrgValues())
      };
      setFormState(newState);

      if (teamState.objData?.orgLevel?.organizationId === NCAA_ORGANIZATION_ID) {
        if (location.state && location.state.personId) {
          setState({ ...state, isEdit: true, isNCAA: true, isSet: true });
        }
        else {
          setState({ ...state, isEdit: false, isNCAA: true, isSet: true });
        }
      }
      else {
        if (location.state && location.state.personId) {
          setState({ ...state, isEdit: true, isNCAA: false, isSet: true });
        }
        else {
          setState({ ...state, isEdit: false, isNCAA: false, isSet: true });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [environmentVariableState, teamState.objData, formState.birthDate, dateState.effectiveDateISL, dateState.expirationDateISL]);

  useEffect(() => {
    if (formState.orgRoleId) {
      getOrgRoleStatus(formState.orgRoleId);

      setFormState({
        ...formState,
        orgRoleStatus: ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.orgRoleId]);

  useEffect(() => {
    if (orgRoleStatusState.isLoaded) {
      setFormState({
        ...formState,
        orgRoleStatus: orgRoleStatusState.data.orgRoleName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleStatusState]);

  useEffect(() => {
    //Edit NCAA Athlete
    if (orgUnitAthleteState.isArrayLoaded === true && competitionGenderState.isArrayLoaded === true &&
      orgUnitAthleteState.isSaving === false && orgUnitAthleteState.isSaved === false && location.state.personId) {
      const selectedAthlete = location?.state?.person;
      const tempYearOfEligibility = yearOfEligibilityOptions.find(x => x.value === selectedAthlete.yearOfEligibility)?.id;
      setFormState({
        ...formState,
        personId: selectedAthlete.personId || '',
        personOrgRoleId: selectedAthlete.personOrgRoleId || Constants.DEFAULT_ID,
        personOrgRoleDurationId: selectedAthlete.personOrgRoleDurationId || Constants.DEFAULT_ID,
        firstName: selectedAthlete.firstName || '',
        lastName: selectedAthlete.lastName || '',
        birthDate: formatDate(selectedAthlete.birthDate) || Constants.BLANK_DATE_STRING,
        competitionGenderTypeId: selectedAthlete.competitionGenderTypeCode || '',
        competitionGenderTypeName: selectedAthlete.competitionGenderTypeName || '',
        yearOfEligibilityId: tempYearOfEligibility,
        yearOfEligibilityName: selectedAthlete.yearOfEligibility || '',
        hasRepresentedIntComp: false,
        ncaaOrgRoleId: selectedAthlete.orgRoleId || Constants.DEFAULT_ID,
        ncaaOrgRoleName: selectedAthlete.sportType
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [orgUnitAthleteState, competitionGenderState.isArrayLoaded]);

  useEffect(() => {
    if (personState.isArrayLoaded === true) {
      if (personState.arrayData.length > 0) {
        setDupGridState(personState.arrayData.map(x => {
          let orgDuration = { orgUnitName: '', effectiveDate: '', expirationDate: '' };
          if (Array.isArray(x.orgDurations) && x.orgDurations.length > 0) {
            orgDuration = {
              orgUnitName: x.orgDurations[0].orgUnitName,
              effectiveDate: x.orgDurations[0].effectiveDate,
              expirationDate: x.orgDurations[0].expirationDate
            };
          }
          return {
            personId: x.personId,
            firstName: x.firstName,
            lastName: x.lastName,
            birthDate: x.birthDate,
            orgDuration,
            isSelected: false
          };
        }));
        setNewGridState([{
          personId: '',
          firstName: formState.firstName,
          lastName: formState.lastName,
          birthDate: formState.birthDate,
          orgDurations: { organization: '', effectiveDate: '', expirationDate: '' },
          isSelected: false
        }]);
      } else {
        const athlete = {
          firstName: formState.firstName.trim(),
          lastName: formState.lastName.trim(),
          birthDate: formState.birthDate,
          competitionGenderTypeId: formState.competitionGenderTypeId,
          isDeleted: false
        };
        postBasePerson(athlete);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.isArrayLoaded]);


  useEffect(() => {
    if (orgUnitAthleteState.isSaving === false && orgUnitAthleteState.isSaved === false
      && personState.isSaving === false && personState.isSaved === true) {
      
      postOrgUnitAthlete(createOrgUnitAthleteObj(personState.objData.personId, formState, teamState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState, orgUnitAthleteState]);

  useEffect(() => {
    if (orgUnitAthleteState.isSaving === false && orgUnitAthleteState.isSaved === true) {
      resetOrgUnitAthleteState();
      navigate(NavLinks.TEAM_ATHLETEROSTER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitAthleteState]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      ncaaOrgRoleOptions = [
          { id: Constants.DEFAULT_ID, name: '--', value: '' },
          { id: NCAA_ATHLETE_ORG_ROLE_ID, name: 'NCAA Swimmer', value: NCAA_ATHLETE_ORG_ROLE_ID },
          { id: NCAA_DIVER_ORG_ROLE_ID, name: 'NCAA Diver', value: NCAA_DIVER_ORG_ROLE_ID }];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState]);

  function submitFormCallback() {
    if (formState.personId) {
      putNcaaAthlete(createPutNcaaAthleteObj(formState, teamState));
    } else {
      getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
    }
  };

  function createOrgUnitAthleteObj(personId, formState, teamState) {
    return {
      personId: personId,
      yearOfEligibility: formState.yearOfEligibilityName !== '' ? formState.yearOfEligibilityName : null,
      orgUnitId: teamState.objData.orgUnitId,
      representedInternationalCompetition: formState.hasRepresentedIntComp === true ? "Yes" : "No",
      competitionGenderTypeId: formState.competitionGenderTypeId,
      orgRoleId: formState.ncaaOrgRoleId.length > 0 ? formState.ncaaOrgRoleId : formState.orgRoleId
    };
  }

  function createPutNcaaAthleteObj(formState, teamState) {
    return {
      personOrgRoleDurationId: formState.personOrgRoleDurationId,
      yearOfEligibility: formState.yearOfEligibilityName,
      orgUnitId: teamState.objData.orgUnitId,
      representedInternationalCompetition: formState.hasRepresentedIntComp === true ? "Yes" : "No",
      competitionGenderTypeId: formState.competitionGenderTypeId,
      orgRoleId: formState.ncaaOrgRoleId.length > 0 ? formState.ncaaOrgRoleId : formState.orgRoleId
    };
  }

  return {
    state,
    teamState,
    orgUnitAthleteState,
    personState,
    newGridState,
    dupGridState,
    competitionGenderState,
    onModalContinueClicked,
    onModalCancelClicked,
    onSelectGridPerson,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked,
    yearOfEligibilityOptions,
    ncaaOrgRoleOptions
  };
};

export default useTeamAthleteWriteDetail;

function getFormInitialState() {
  return {
    personId: '',
    personOrgRoleId: Constants.DEFAULT_ID,
    personOrgRoleDurationId: Constants.DEFAULT_ID,
    organizationId: Constants.DEFAULT_ID,
    organizationName: '',
    isNcaa: false,
    orgRoleId: '',
    orgRoleStatus: '',
    effectiveDate: Constants.BLANK_DATE_STRING,
    expirationDate: Constants.BLANK_DATE_STRING,
    firstName: '',
    lastName: '',
    birthDate: Constants.BLANK_DATE_STRING,
    competitionGenderTypeId: '',
    competitionGenderTypeName: '',
    yearOfEligibilityId: Constants.DEFAULT_ID,
    yearOfEligibilityName: '',
    hasRepresentedIntComp: false,
    ncaaOrgRoleId: '',
    ncaaOrgRoleName: ''
  };
};