import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';

const GetTeamMeetData = (orgUnitId, reportPeriodId, crossOrganization = undefined, state, setState) => {
  if (state.isArrayLoading !== true && orgUnitId && !state.message) {
    const newState = {
      ...state,
      orgUnitId,
      reportPeriodId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const reportPeriodIdForUrl = reportPeriodId ? encodeURIComponent(reportPeriodId) : undefined;
    const crossOrganizationForUrl = crossOrganization ?? undefined;

    let url = '';

    if (crossOrganizationForUrl === undefined && reportPeriodIdForUrl === undefined) {
      url = `/Meet/OrgUnit/TeamMeet?orgUnitId=${orgUnitIdForUrl}`
    }
    else if (crossOrganizationForUrl === undefined && reportPeriodIdForUrl !== undefined) {
      url = `/Meet/OrgUnit/TeamMeet?orgUnitId=${orgUnitIdForUrl}&reportPeriodId=${reportPeriodIdForUrl}`;
    }
    else if (crossOrganizationForUrl !== undefined && reportPeriodIdForUrl === undefined) {
      url = `/Meet/OrgUnit/TeamMeet?orgUnitId=${orgUnitIdForUrl}&crossOrganization=${crossOrganizationForUrl === true ? true : false}`
    }
    else if (crossOrganizationForUrl !== undefined && reportPeriodIdForUrl !== undefined) {
      url = `/Meet/OrgUnit/TeamMeet?orgUnitId=${orgUnitIdForUrl}&reportPeriodId=${reportPeriodIdForUrl}&crossOrganization=${crossOrganizationForUrl === true ? true : false}`
    }

    MeetHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            orgUnitId,
            reportPeriodId,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetTeamMeetData;