import React, { Fragment } from 'react';

import useSearchTeam from './UseSearchTeam';

import SearchTeamForm from './SearchTeamForm';
import SearchTeamGrid from './SearchTeamGrid';

import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import Headings from '../../../common/components/headings/Headings';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Constants from '../../../common/utils/Constants';

import global from '../../../common/components/GlobalStyle.module.css';


const SearchTeam = ({ pageTitle, onEdit, searchTeamState, onCancelClicked, teamAddLink }) => {
  const {
    otherOrgOrgUnitState,
    contextSecurityState,
    formState,
    errorState,
    teamState,
    clearForm,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    sortByOptions,
    onToggleFilters,
    onAddClicked
  } = useSearchTeam({teamAddLink});

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      {searchTeamState !== undefined ?
        //toggles filters and results
        <Fragment>
          <div className={searchTeamState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
            <SearchTeamForm
              otherOrgOrgUnitState={otherOrgOrgUnitState}
              formState={formState}
              errorState={errorState}
              clearForm={clearForm}
              handleSubmit={handleSubmit}
              onFormValueChanged={onFormValueChanged}
              onValueTextPairChanged={onValueTextPairChanged}
              sortByOptions={sortByOptions}
              onCancelClicked={onCancelClicked}
              searchTeamState={searchTeamState}
              onAddClicked={onAddClicked}
            />
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchTeamGrid
                teamState={teamState}
                searchTeamState={searchTeamState}
                onEdit={onEdit}
                onToggleFilters={onToggleFilters}
                onCancelClicked={onCancelClicked}
              />
            </div>
          </div>
          {onCancelClicked && searchTeamState.showFilters === false ?
            <Fragment>
              <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                  <SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton>
                </div>
              </div>
            </Fragment> : <Fragment />}
        </Fragment>
        :
        //shows filters and results at the same time
        <Fragment>
          <SearchTeamForm
            otherOrgOrgUnitState={otherOrgOrgUnitState}
            formState={formState}
            errorState={errorState}
            clearForm={clearForm}
            handleSubmit={handleSubmit}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged}
            sortByOptions={sortByOptions}
            onAddClicked={onAddClicked}
          />
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchTeamGrid
                teamState={teamState}
                onEdit={onEdit}
              />
            </div>
          </div>
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={teamState.isArrayLoading || teamState.isObjLoading || otherOrgOrgUnitState.isArrayLoading || contextSecurityState.isObjLoading} />
    </Fragment >
  )
}

export default SearchTeam;