import { useState } from 'react';

import TeamFacilityLinksData from './TeamFacilityLinksData';

const useTeamFacilityLinksNoContextData = () => {
  const [teamFacilityLinksState, setTeamFacilityLinksState] = useState(TeamFacilityLinksData.INITIAL_STATE);

  const getTeamFacilityLinks = (orgUnitId) => 
    TeamFacilityLinksData.getTeamFacilityLinksData(orgUnitId, teamFacilityLinksState, setTeamFacilityLinksState);

  return {
    teamFacilityLinksState,
    setTeamFacilityLinksState,
    getTeamFacilityLinks
  }
}

export default useTeamFacilityLinksNoContextData;