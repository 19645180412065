import React, { createContext, useState } from 'react';

import OtherOrgUnitData from './OtherOrgOrgUnitData';

export const OtherOrgUnitStateContext = createContext();

const OtherOrgUnitContextProvider = ({ children }) => {
  const stateHook = useState(OtherOrgUnitData.INITIAL_STATE);

  return (
    <OtherOrgUnitStateContext.Provider value={stateHook}>
      {children}
    </OtherOrgUnitStateContext.Provider>
  );
};

export default OtherOrgUnitContextProvider;