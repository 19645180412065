import { useState } from 'react';

import OrganizationOrgLevelData from './OrganizationOrgLevelData';

const useOrganizationOrgLevelData = () => {
  const [organizationOrgLevelState, setOrganizationOrgLevelState] = useState(OrganizationOrgLevelData.INITIAL_STATE);

  const getOrganizationOrgLevels = () => OrganizationOrgLevelData.getOrganizationOrgLevelData(organizationOrgLevelState, setOrganizationOrgLevelState);

  return {
    organizationOrgLevelState,
    getOrganizationOrgLevels
  };
};

export default useOrganizationOrgLevelData;