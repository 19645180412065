import { useState } from 'react';

import ParentOrgUnitData from './ParentOrgUnitData';

const useParentOrgUnitData = () => {
  const [parentOrgUnitState, setParentOrgUnitState] = useState(ParentOrgUnitData.INITIAL_STATE);

  const getParentOrgUnits = (orgLevelId) => ParentOrgUnitData.getParentOrgUnitByOrgLevelData(orgLevelId, parentOrgUnitState, setParentOrgUnitState);
  const resetState = () => {setParentOrgUnitState(ParentOrgUnitData.INITIAL_STATE)};

  return {
    parentOrgUnitState,
    getParentOrgUnits,
    resetState
  };
};

export default useParentOrgUnitData;