import { useContext, useState } from 'react';

import { TeamStateContext } from './TeamContextProvider';
import { TeamFiltersStateContext } from './TeamFiltersContextProvider';

import TeamData from './TeamData';

const useTeamData = () => {
  const [teamState, setTeamState] = useContext(TeamStateContext);
  const [teamFiltersState, setTeamFiltersState] = useContext(TeamFiltersStateContext);
  const [teamStateNoContext, setTeamStateNoContext] = useState(TeamData.INITIAL_STATE);

  const searchTeams = (filterObject, sortBy) => {
    setTeamFiltersState({ ...teamFiltersState, filterObject, sortBy });
    const filterObj = JSON.parse(JSON.stringify(filterObject));
    if (filterObj.teamOrgUnitName) {
      filterObj.teamOrgUnitName = undefined;
    }
    TeamData.searchTeamsData(filterObj, sortBy, teamState, setTeamState);
  }

  const getTeam = (orgUnitId) => {
    TeamData.getTeamData(orgUnitId, teamState, setTeamState);
  };

  const getTeamNoContext = (orgUnitId) => {
    TeamData.getTeamData(orgUnitId, teamStateNoContext, setTeamStateNoContext);
  };

  const putTeam = (orgUnitId, orgUnitObj) => {
    TeamData.putTeamData(orgUnitId, orgUnitObj, teamState, setTeamState);
  };

  const postTeam = (orgUnitObj) => {
    TeamData.postTeamData(orgUnitObj, teamState, setTeamState);
  };

  const clearObjData = () => {
    setTeamState({
      ...teamState,
      isObjLoading: false,
      isObjLoaded: false,
      objData: {},
      message: ''
    });
  };

  const clearArrayData = () => {
    setTeamState({
      ...teamState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: false,
      message: ''
    });
  };

  return {
    teamState,
    teamFiltersState,
    teamStateNoContext,
    setTeamFiltersState,
    searchTeams,
    getTeam,
    getTeamNoContext,
    putTeam,
    postTeam,
    clearObjData,
    clearArrayData
  };
};

export default useTeamData;