import React, { createContext, useState } from 'react';

import OrgUnitStaffData from './OrgUnitStaffData';

export const OrgUnitStaffStateContext = createContext();

const OrgUnitStaffContextProvider = ({ children }) => {
  const stateHook = useState(OrgUnitStaffData.INITIAL_STATE);

  return (
    <OrgUnitStaffStateContext.Provider value={stateHook}>
      {children}
    </OrgUnitStaffStateContext.Provider>
  );
};

export default OrgUnitStaffContextProvider;