import { useContext } from 'react';

import { OrgUnitStaffStateContext } from './OrgUnitStaffContextProvider';

import OrgUnitStaffData from './OrgUnitStaffData';

const useOrgUnitStaffData = () => {
  const [orgUnitStaffState, setOrgUnitStaffState] = useContext(OrgUnitStaffStateContext);

  const getOrgUnitStaff = (orgUnitId, roleGroupId, isCurrentOnly) => { OrgUnitStaffData.getOrgUnitStaffData(orgUnitId, roleGroupId, isCurrentOnly, orgUnitStaffState, setOrgUnitStaffState); };
  const postOrgUnitStaff = (orgUnitId, roleGroupId, staffObj) => { OrgUnitStaffData.postOrgUnitStaffData(orgUnitId, roleGroupId, staffObj, orgUnitStaffState, setOrgUnitStaffState); };
  const putOrgUnitStaff = (orgUnitId, personId, roleGroupId, staffObj) => { OrgUnitStaffData.putOrgUnitStaffData(orgUnitId, personId, roleGroupId, staffObj, orgUnitStaffState, setOrgUnitStaffState); };
  const deleteOrgUnitStaff = (personOrgRoleDurationId) => { OrgUnitStaffData.deleteOrgUnitStaffData(personOrgRoleDurationId, orgUnitStaffState, setOrgUnitStaffState); };

  const resetOrgUnitStaffState = () => {
    setOrgUnitStaffState(OrgUnitStaffData.INITIAL_STATE);
  };

  const confirmOrgUnitStaffStateSaved = () => {
    setOrgUnitStaffState({
      ...orgUnitStaffState,
      isSaved: false
    });
  };

  return {
    orgUnitStaffState,
    getOrgUnitStaff,
    postOrgUnitStaff,
    putOrgUnitStaff,
    deleteOrgUnitStaff,
    resetOrgUnitStaffState,
    confirmOrgUnitStaffStateSaved
  };
};

export default useOrgUnitStaffData;