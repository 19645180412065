import React, { Fragment } from 'react';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

import LargeTeamFacilityWriteGrid from './LargeTeamFacilityWriteGrid';
import SmallTeamFacilityWriteGrid from './SmallTeamFacilityWriteGrid';

const TeamFacilityWriteGrid = ({ state, onEdit, onDelete, onAddPool, onEditPool, onDeletePool }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LargeTeamFacilityWriteGrid
        state={state}
        onEdit={onEdit}
        onDelete={onDelete}
        onAddPool={onAddPool}
        onEditPool={onEditPool}
        onDeletePool={onDeletePool}
        expandedId={expandedId}
        onClick={onClick} />
      <SmallTeamFacilityWriteGrid
        state={state}
        onEdit={onEdit}
        onDelete={onDelete}
        onAddPool={onAddPool}
        onEditPool={onEditPool}
        onDeletePool={onDeletePool}
        expandedId={expandedId}
        onClick={onClick} />
    </Fragment>
  );
};

export default TeamFacilityWriteGrid;