import React, { Fragment } from 'react';

import useTeamFacilityAdd from './UseTeamFacilityAdd';

import NavLinks from '../NavLinks';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import AddFacility from '../../../../common/components/searches/searchFacility/addFacility/AddFacility';
import Constants from '../../../../common/utils/Constants';

const TeamFacilityAdd = () => {
  const {
    teamState
  } = useTeamFacilityAdd();

  return (
    <Fragment>
      <AddFacility
        saveUrl={NavLinks.TEAM_FACILITY_DETAIL}
        cancelUrl={NavLinks.TEAM_FACILITY_SEARCH}
        orgUnitId={teamState.objData.orgUnitId} />
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin"></div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={teamState.isSaving} />
    </Fragment>
  );
};

export default TeamFacilityAdd;