import React, { Fragment } from 'react';

import useTeamFacilitySearch from './UseTeamFacilitySearch';

import SearchFacility from '../../../../common/components/searches/searchFacility/SearchFacility';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const TeamFacilitySearch = () => {
  const {
    teamState,
    facilityState,
    modalState,
    onLinkFacility,
    onCancelClicked,
    onModalCancel,
    onAddANewFacility
  } = useTeamFacilitySearch();

  return (
    <Fragment>
      <SearchFacility
        pageTitle={"Facility Search"}
        onEdit={onLinkFacility}
        onCancelClicked={onCancelClicked}
        onAddANewFacility={onAddANewFacility}
        contactLabel={'Team'} />
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin"></div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={teamState.isSaving || facilityState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Duplicate Facility Error"
        displayPopUp={modalState.displayModal}
        onModalCanceled={onModalCancel}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.facilityName} is already linked to this team</p>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default TeamFacilitySearch;