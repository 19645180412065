import { useContext } from 'react';

import TeamMeetData from './TeamMeetData';

import { TeamMeetStateContext } from './TeamMeetContextProvider';

const useTeamMeetData = () => {
  const [teamMeetState, setTeamMeetState] = useContext(TeamMeetStateContext);
  const getTeamMeet = (orgUnitId, reportPeriodId, crossOrganization = undefined) => { TeamMeetData.getTeamMeetData(orgUnitId, reportPeriodId, crossOrganization, teamMeetState, setTeamMeetState); };
  const deleteMeet = (meetId) => { TeamMeetData.deleteMeetData(meetId, teamMeetState, setTeamMeetState); };

  const confirmSave = () => {
    setTeamMeetState({
      ...teamMeetState,
      isSaved: false
    });
  };

  const resetTeamMeetState = () => {
    setTeamMeetState(TeamMeetData.INITIAL_STATE);
  };

  return {
    teamMeetState,
    setTeamMeetState,
    getTeamMeet,
    deleteMeet,
    confirmSave,
    resetTeamMeetState
  };
};

export default useTeamMeetData;