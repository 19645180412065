import { useEffect } from 'react';

import validate from './TeamChangesValidation';

import useForm from '../../../../common/utils/UseForm';
import { DEFAULT_ID } from '../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';

import useTeamData from '../../../state/team/UseTeamData';
import useOtherOrgOrgUnitTopLevelsData from '../../../state/otherOrgOrgUnitTopLevels/UseOtherOrgOrgUnitTopLevelsData';
import useOtherOrgOrgUnitChangesData from '../../../state/otherOrgOrgUnitChanges/UseOtherOrgOrgUnitChangeData';

const useTeamChanges = () => {
    const { teamState } = useTeamData();
    const { otherOrgOrgUnitTopLevelsState, getOtherOrgOrgUnitTopLevels } = useOtherOrgOrgUnitTopLevelsData();
    const { otherOrgOrgUnitChangeState, putOtherOrgOrgUnitChange } = useOtherOrgOrgUnitChangesData();
    const { formState, errorState, setFormState, onFormValueChanged, handleSubmit } = useForm(initialState, submitFormCallback, validate);

    function createOrgUnitObj() {
        return {
            parentOrgUnitId: formState.orgUnit.length > 0 ? formState.orgUnit[0].id : undefined,
            orgUnitName: formState.teamOrgUnitName,
            orgUnitCode: formState.teamOrgUnitCode
        };
    };

    function submitFormCallback() {
        putOtherOrgOrgUnitChange(teamState.objData.orgUnitId, createOrgUnitObj());
    };

    useEffect(() => {
        if (otherOrgOrgUnitTopLevelsState.isArrayLoaded === true) {
            setFormState({
                ...formState,
                orgUnitId: teamState.objData.parentOrgUnitId,
                orgUnit: HierarchicalDataUtils.GetNameIdPairs(otherOrgOrgUnitTopLevelsState.treeData, [teamState.objData.parentOrgUnitId]) || [],
                teamOrgUnitCode: teamState.objData.orgUnitCode,
                teamOrgUnitName: teamState.objData.orgUnitName
              });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherOrgOrgUnitTopLevelsState.isArrayLoaded]);

    useEffect(() => {
        if (teamState.isObjLoaded === true) {
            getOtherOrgOrgUnitTopLevels();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function initialState() {
        return {
            orgUnitId: DEFAULT_ID,
            orgUnit: [],
            teamOrgUnitId: '',
            teamOrgUnitName: '',
            teamOrgUnitCode: '',
            parentOrgUnitId: DEFAULT_ID,
            parentOrgUnitName: ''
        };
    };

    return {
        teamState,
        isSaving: otherOrgOrgUnitChangeState !== undefined ? otherOrgOrgUnitChangeState.isSaving : false,
        treeData: otherOrgOrgUnitTopLevelsState.isArrayLoaded ? 
            otherOrgOrgUnitTopLevelsState.treeData.filter(x => x.name === teamState.objData.organization.organizationName) : [],
        isTreeLoading: otherOrgOrgUnitTopLevelsState.isArrayLoading === true,
        formState,
        errorState,
        treeViewLabel: teamState.objData.organization.organizationId === 2 ? "Country" 
            : teamState.objData.organization.organizationId === 3 ? "Conference"
            : teamState.objData.organization.organizationId === 6 ? "Team" : "Organization",
        onFormValueChanged,
        handleSubmit
    };
};

export default useTeamChanges;