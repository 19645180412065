import { useContext } from 'react';

import { OtherOrgUnitStateContext } from './OtherOrgUnitContextProvider';
import OtherOrgOrgUnitData from './OtherOrgOrgUnitData';

const useOtherOrgOrgUnitData = () => {
  const [otherOrgOrgUnitState, setOtherOrgOrgUnitState] = useContext(OtherOrgUnitStateContext);

  const getOtherOrgOrgUnits = () => OtherOrgOrgUnitData.getOtherOrgOrgUnitData(otherOrgOrgUnitState, setOtherOrgOrgUnitState);

  return {
    otherOrgOrgUnitState,
    getOtherOrgOrgUnits
  };
};

export default useOtherOrgOrgUnitData;