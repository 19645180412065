import { useContext } from 'react';

import { TeamStaffRolesContext } from './TeamStaffRolesContextProvider';

import TeamStaffRolesData from './TeamStaffRolesData';

const useTeamStaffRolesData = () => {
  const [teamStaffRolesState, setTeamStaffRolesState] = useContext(TeamStaffRolesContext);

  const getTeamStaffRoles = (roleGroupId) => TeamStaffRolesData.getTeamStaffRoles(roleGroupId, teamStaffRolesState, setTeamStaffRolesState);

  return {
    teamStaffRolesState,
    getTeamStaffRoles
  };
};

export default useTeamStaffRolesData;