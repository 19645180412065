import React, { Fragment } from 'react';

import useTeamMeet from './UseTeamMeet';

import TeamMeetGridWrite from './TeamMeetGridWrite';

import ReportPeriodTreeView from '../../../../common/components/tree/reportPeriodTree/ReportPeriodTreeView';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const TeamMeetWrite = () => {
  const { filterState, setFilterState, teamState, teamMeetState, gridState, onAddMeet,
    onDeleteMeet, onUploadResults, Constants, onOpenModalClicked, modalState, onModalCanceled } = useTeamMeet();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Team Meets</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={() => onAddMeet()}>Add Meet</ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-6">
          <ReportPeriodTreeView
            label="Report Period"
            name="reportPeriod"
            value={filterState.reportPeriod}
            selectableLeavesOnly={false}
            singleSelect={true}
            onChange={(newValue) => {
              setFilterState({ ...filterState, reportPeriod: newValue });
            }}
            organizationId={teamState.objData?.orgLevel?.organizationId || Constants.DEFAULT_ID}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <TeamMeetGridWrite
            state={teamMeetState}
            gridState={gridState}
            onUpload={onUploadResults}
            onDelete={onOpenModalClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Meet:</b> {modalState.meetInfo}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteMeet}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={teamMeetState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={teamMeetState.isArrayLoading} />
    </Fragment>
  );
};

export default TeamMeetWrite;