import React, { Fragment } from 'react';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

import LargeTeamFacilityReadGrid from './LargeTeamFacilityReadGrid';
import SmallTeamFacilityReadGrid from './SmallTeamFacilityReadGrid';

const TeamFacilityReadGrid = ({ state }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <LargeTeamFacilityReadGrid state={state} expandedId={expandedId} onClick={onClick} />
      <SmallTeamFacilityReadGrid state={state} expandedId={expandedId} onClick={onClick} />
    </Fragment>
  );
};

export default TeamFacilityReadGrid;