import { Fragment } from "react";

import useTeamAdd from "./UseTeamAdd";

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import TreeView from "../../../../common/components/tree/TreeView";
import Input from "../../../../common/components/inputs/Input";
import ParentOrgUnitCombobox from "../../../components/comboboxes/ParentOrgUnitCombobox";
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from "../../../../common/utils/Constants";

const TeamAdd = () => {
  const {
    teamState,
    contextSecurityState,
    formState,
    errorState,
    treeData,
    isTreeLoading,
    selectedOrgLevel,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onBack
  } = useTeamAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Team</Headings.H3>
        </div>
      </div>
      <form noValidate onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <TreeView
              label="Org Level*"
              name="orgLevel"
              value={formState.orgLevel}
              options={treeData}
              selectableLeavesOnly={true}
              singleSelect={true}
              error={errorState.orgLevel}
              message={errorState.orgLevel}
              isLoading={isTreeLoading}
              onChange={(newValue) => { onFormValueChanged('orgLevel', newValue) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ParentOrgUnitCombobox
              label={"Parent Org Unit*"}
              name={"parentOrgUnitName"}
              valueToMatch={formState.parentOrgUnitName}
              error={errorState.parentOrgUnitId}
              message={errorState.parentOrgUnitId}
              onChange={(newValue, newLabel) => { onValueTextPairChanged(newValue, 'parentOrgUnitId', newLabel, 'parentOrgUnitName') }}
              orgLevelBelow={selectedOrgLevel} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label={"Team Name*"}
              name={"teamName"}
              value={formState.teamName}
              error={errorState.teamName}
              message={errorState.teamName}
              onChange={(newValue) => { onFormValueChanged('teamName', newValue) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label={"Team Short Name"}
              name={"teamShortName"}
              value={formState.teamShortName}
              error={errorState.teamShortName}
              message={errorState.teamShortName}
              onChange={(newValue) => { onFormValueChanged('teamShortName', newValue) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label={"Team Code*"}
              name={"teamCode"}
              value={formState.teamCode}
              error={errorState.teamCode}
              message={errorState.teamCode}
              onChange={(newValue) => { onFormValueChanged('teamCode', newValue) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={() => onBack()}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isTreeLoading || teamState.isObjLoading || contextSecurityState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={teamState.isSaving} />
    </Fragment>
  );
};

export default TeamAdd;