import { Fragment } from 'react';
import useOtherTeamAthleteRoster from '../../teamAthleteRoster/otherTeamAthleteRoster/UseOtherTeamAthleteRoster';
import Headings from '../../../../../common/components/headings/Headings';

//import OtherOrgOrgUnitCombobox from '../../../../components/comboboxes/OtherOrgOrgUnitCombobox';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';
import ClubRosterExport from '../../../../../common/components/fileExports/ClubRosterExport';
import OrgUnitCombobox from '../../../../../common/components/comboboxes/orgUnitCombobox/OrgUnitCombobox';

const OtherTeamAthleteRoster = () => {
  const { 
    formState, 
    errorState, 
    exportState, 
    teamStateNoContext, 
    orgUnitAthleteState, 
    handleSubmit, 
    onValueTextPairChanged,
    NCAA_ORGANIZATION_ID } = useOtherTeamAthleteRoster();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Other Team Athlete Roster</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <OrgUnitCombobox 
              label="Team Name (Parent Code/Team Code)"
              name="teamOrgUnitName"
              valueToMatch={formState.teamOrgUnitName}
              error={errorState.teamOrgUnitId}
              message={errorState.teamOrgUnitId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'teamOrgUnitId', newValueLabel, 'teamOrgUnitName', e); }}
              organizationId={NCAA_ORGANIZATION_ID}
              lscId={Constants.DEFAULT_ID}
            />
          </div>
        </div>
        {teamStateNoContext.isObjLoaded === true &&
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
              <ClubRosterExport
                clubRosterArray={exportState}
                orgUnitCode={teamStateNoContext.objData.orgUnitCode || ""}
                orgUnitName={teamStateNoContext.objData.orgUnitName || ""}
                parentOrgUnitCode={teamStateNoContext.objData.parentOrgUnitCode || ""}
                clubCity={""}
                clubStateCode={""}
                clubAddress1={""}
                clubPostalCode={""}
                clubEmail={""}
                rosterType={"NCAA"}
              />
            
          </div>
          <div className="col-xs-12 col-sm-4 pull-right">
            {/* <ReportPeriodTreeView
              label="Report Period"
              name="reportPeriod"
              value={filterState.reportPeriod}
              selectableLeavesOnly={false}
              singleSelect={true}
              onChange={(newValue) => {
                setFilterState({ ...filterState, reportPeriod: newValue });
              }}
              organizationId={teamStateNoContext.objData?.orgLevel?.organizationId || Constants.DEFAULT_ID}
            /> */}
          </div>
        </div>
        }
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={orgUnitAthleteState.isArrayLoading || teamStateNoContext.isObjLoading} />
    </Fragment>
  );
};

export default OtherTeamAthleteRoster;
