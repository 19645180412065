import { useContext } from 'react';

import { ReportPeriodStateContext } from './ReportPeriodContextProvider';
import ReportPeriodData from './ReportPeriodData';

const useReportPeriodData = () => {
  const [reportPeriodState, setReportPeriodState] = useContext(ReportPeriodStateContext);

  const getReportPeriodsByOrganizationId = (organizationId) => ReportPeriodData.getReportPeriodsByOrganizationIdData(organizationId, reportPeriodState, setReportPeriodState);

  return {
    reportPeriodState,
    getReportPeriodsByOrganizationId
  };
};

export default useReportPeriodData;