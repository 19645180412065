import React from 'react';

import useTeamReporting from './UseTeamReporting';

import ReportList from '../../../../common/components/embeddedReports/ReportList';

const TeamReporting = () => {
  const { reportParameters, contextId, routeName } = useTeamReporting();

  return (
    <ReportList listTitle="Team Reports" 
      routeName={routeName}
      contextId={contextId}
      reportParameters={reportParameters} />
  );
};

export default TeamReporting;