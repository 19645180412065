import { useContext } from 'react';

import { OrgUnitAthleteStateContext } from './OrgUnitAthleteContextProvider';

import OrgUnitAthleteData from './OrgUnitAthleteData';

const useOrgUnitAthleteData = () => {
  const [orgUnitAthleteState, setOrgUnitAthleteState] = useContext(OrgUnitAthleteStateContext);
  const getOrgUnitAthlete = (orgUnitId) => { OrgUnitAthleteData.getOrgUnitAthleteData(orgUnitId, orgUnitAthleteState, setOrgUnitAthleteState) };
  const getOrgUnitAthletePiiSafe = (orgUnitId) => { OrgUnitAthleteData.getOrgUnitAthletePiiSafeData(orgUnitId, orgUnitAthleteState, setOrgUnitAthleteState) };
  const postOrgUnitAthlete = (orgUnitAthleteObj) => { OrgUnitAthleteData.postOrgUnitAthleteData(orgUnitAthleteObj, orgUnitAthleteState, setOrgUnitAthleteState) };
  const postOrgUnitAthleteRosterRollover = (rosterRolloverObj) => { OrgUnitAthleteData.postOrgUnitAthleteRosterRolloverData(rosterRolloverObj, orgUnitAthleteState, setOrgUnitAthleteState) };
  const putNcaaAthlete = (putNcaaAthleteObj) => { OrgUnitAthleteData.putNcaaAthleteData(putNcaaAthleteObj, orgUnitAthleteState, setOrgUnitAthleteState) };
  const deleteOrgUnitAthlete = (personRoleDurationId) => { OrgUnitAthleteData.deleteOrgUnitAthleteData(personRoleDurationId, orgUnitAthleteState, setOrgUnitAthleteState) };

  const resetOrgUnitAthleteState = () => {
    setOrgUnitAthleteState(OrgUnitAthleteData.INITIAL_STATE);
  };

  return {
    orgUnitAthleteState,
    getOrgUnitAthlete,
    getOrgUnitAthletePiiSafe,
    postOrgUnitAthlete,
    postOrgUnitAthleteRosterRollover,
    putNcaaAthlete,
    deleteOrgUnitAthlete,
    resetOrgUnitAthleteState
  };
};

export default useOrgUnitAthleteData;