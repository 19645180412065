import { isValidName } from '../../../common/utils/validation';

const REQUIRED_FILTER_COUNT = 1;

export const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;

  if (formState.orgUnit.length > 0) {
    filterCount += 1;
  }

  if (formState.teamOrgUnitId) {
    filterCount += 1;
  }

  if (formState.coachLastName.trim() !== '') {
    filterCount += 1;
    if (!isValidName(formState.coachLastName.trim())) {
      errors.coachLastName = 'Coach Last Name cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  if (filterCount < REQUIRED_FILTER_COUNT) {
    errors.filter = 'Please enter information in at least one search filter'
  }

  return errors;
};

const SearchTeamValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default SearchTeamValidation;