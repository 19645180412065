import {BASIC_INITIAL_STATE} from '../../../common/utils/HttpHelper';

import getOtherOrgOrgUnitData from './GetOtherOrgOrgUnitData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  treeData: []
};

const OtherOrgOrgUnitData = {
  INITIAL_STATE, getOtherOrgOrgUnitData
};

export default OtherOrgOrgUnitData;