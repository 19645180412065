import React, { Fragment } from 'react';

import useTeamSearch from './UseTeamSearch';

import SearchTeam from '../../../components/searchTeam/SearchTeam';

const TeamSearch = () => {
  const { searchTeamState, onClick, teamAddLink } = useTeamSearch();

  return (
    <Fragment>
      <SearchTeam
        pageTitle={"Team Search"}
        onEdit={onClick}
        searchTeamState={searchTeamState}
        teamAddLink={teamAddLink}
      />
    </Fragment>
  );
};

export default TeamSearch;