import React from 'react';

import useOtherOrgOrgUnitCombobox from './UseOtherOrgOrgUnitCombobox';

import Combobox from '../../../common/components/comboboxes/Combobox';

import global from '../../../common/components/GlobalStyle.module.css';

const OtherOrgOrgUnitCombobox = ({ label, name, valueToMatch, error, message, onChange }) => {
  const { otherOrgOrgUnitTeamNameState } = useOtherOrgOrgUnitCombobox();

  return otherOrgOrgUnitTeamNameState.message
    ? <span className={global.LoadingMsg}>{otherOrgOrgUnitTeamNameState.message}</span>
    : (
      <Combobox
        label={label}
        name={name}
        isLoading={otherOrgOrgUnitTeamNameState.isLoading}
        items={otherOrgOrgUnitTeamNameState.items}
        valueToMatch={valueToMatch}
        error={error}
        message={message}
        onChange={onChange} />
    );
};

export default OtherOrgOrgUnitCombobox;