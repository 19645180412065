import React, { createContext, useState } from 'react';

import TeamMeetData from './TeamMeetData';

export const TeamMeetStateContext = createContext();

const TeamMeetContextProvider = ({ children }) => {
  const stateHook = useState(TeamMeetData.INITIAL_STATE);

  return (
    <TeamMeetStateContext.Provider value={stateHook}>
      {children}
    </TeamMeetStateContext.Provider>
  );
};

export default TeamMeetContextProvider;