import { DEFAULT_ID } from "../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.orgLevel.length === 0) {
    errors.orgLevel = 'Org Level is Required';
  }

  if (formState.parentOrgUnitId === DEFAULT_ID) {
    errors.parentOrgUnitId = 'Parent Org Unit is Required';
  }

  if (formState.teamName.trim() === '') {
    errors.teamName = 'Team Name is Required';
  } else if (formState.teamName.trim().length > 100) {
    errors.teamName = 'Team Name cannot exceed 100 characters';
  }

  if (formState.teamCode.trim() === '') {
    errors.teamCode = 'Team Code is Required';
  } else if (formState.teamCode.trim().length > 15) {
    errors.teamCode = 'Team Code cannot exceed 15 characters';
  }

  if (formState.teamShortName.trim().length > 50) {
    errors.teamShortName = 'Team Short Name cannot exceed 50 characters';
  }

  return errors;
};

const TeamAddValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TeamAddValidation;