import React, { Fragment } from 'react';

import useTeamStaffDetail from './UseTeamStaffDetail';

import TeamStaffDupGrid from './TeamStaffDupGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Input from '../../../../common/components/inputs/Input';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import TeamStaffRoleMultiselect from '../../../components/multiselect/TeamStaffRoleMultiselect';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import InternationalPhoneInput from '../../../../common/components/inputs/InternationalPhoneInput';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import SearchMemberPopup from '../../../../common/components/searches/searchMember/SearchMemberPopup';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const EditStaff = ({ formState, errorState, onFormValueChanged, getRoleGroupId, teamTypeCodeOptions,
  onValueTextPairChanged }) => (
  <Fragment>
    <div className="row usas-extra-bottom-margin">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="First Name"
          name="firstName"
          value={formState.firstName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Last Name"
          name="lastName"
          value={formState.lastName} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Birth Date"
          name="birthDate"
          value={formState.birthDate} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Phone Number"
          name="phoneNumber"
          value={formatPhoneNumber(formState.phoneNumber)} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Email*"
          name="email"
          value={formState.email}
          error={errorState.email}
          message={errorState.email}
          onChange={(value) => { onFormValueChanged('email', value); }} />
      </div>
      {formState.organizationId > 0 ?
        <div className="col-xs-12 col-sm-6 col-md-4">
          <TeamStaffRoleMultiselect
            label="Staff Role(s)*"
            name="staffRoles"
            value={formState.staffRoles}
            error={errorState.staffRoles}
            message={errorState.staffRoles}
            onChange={(newValue) => { onFormValueChanged('staffRoles', newValue); }}
            roleGroupId={getRoleGroupId(formState.organizationId)} />
        </div> : <Fragment />}
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Effective Date*"
          name="effectiveDate"
          value={formState.effectiveDate}
          error={errorState.effectiveDate}
          message={errorState.effectiveDate}
          onChange={(value) => { onFormValueChanged('effectiveDate', value); }}
          countOfYears={150}
          startYearOffset={-149} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Team Type*"
          name="teamTypeCode"
          options={teamTypeCodeOptions}
          value={formState.teamTypeCode}
          error={errorState.teamTypeCode}
          message={errorState.teamTypeCode}
          showEmptySelection={false}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'teamTypeCode', newValueLabel, 'teamTypeCodeName');
          }} />
      </div>
    </div>
  </Fragment>
);

const AddStaffByMemberId = ({ formState, errorState, onFormValueChanged, getRoleGroupId, onValueTextPairChanged,
  teamTypeCodeOptions, setFormData }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <SearchMemberPopup label="New Staff Member Id" formState={formState} errorState={errorState} setFormData={setFormData} onFormValueChanged={onFormValueChanged} />
      </div>
      {formState.organizationId > 0 ?
        <div className="col-xs-12 col-sm-6 col-md-4">
          <TeamStaffRoleMultiselect
            label="Staff Role(s)*"
            name="staffRoles"
            value={formState.staffRoles}
            error={errorState.staffRoles}
            message={errorState.staffRoles}
            onChange={(value) => { onFormValueChanged('staffRoles', value); }}
            roleGroupId={getRoleGroupId(formState.organizationId)} />
        </div> : <Fragment />}
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Effective Date*"
          name="effectiveDate"
          value={formState.effectiveDate}
          error={errorState.effectiveDate}
          message={errorState.effectiveDate}
          onChange={(value) => { onFormValueChanged('effectiveDate', value); }}
          countOfYears={150}
          startYearOffset={-149} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Team Type*"
          name="teamTypeCode"
          options={teamTypeCodeOptions}
          value={formState.teamTypeCode}
          error={errorState.teamTypeCode}
          message={errorState.teamTypeCode}
          showEmptySelection={false}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'teamTypeCode', newValueLabel, 'teamTypeCodeName');
          }} />
      </div>
    </div>
  </Fragment>
);

const AddStaffWithoutMemberId = ({ formState, errorState, onFormValueChanged, getRoleGroupId, onValueTextPairChanged,
  teamTypeCodeOptions }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="First Name*"
          name="firstName"
          value={formState.firstName}
          error={errorState.firstName}
          message={errorState.firstName}
          onChange={(value) => { onFormValueChanged('firstName', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Last Name*"
          name="lastName"
          value={formState.lastName}
          error={errorState.lastName}
          message={errorState.lastName}
          onChange={(value) => { onFormValueChanged('lastName', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Birth Date*"
          name="birthDate"
          value={formState.birthDate}
          error={errorState.birthDate}
          message={errorState.birthDate}
          onChange={(value) => { onFormValueChanged('birthDate', value); }}
          countOfYears={150}
          startYearOffset={-149} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <InternationalPhoneInput
          label="Phone Number*"
          name="phoneNumber"
          value={formState.phoneNumber}
          error={errorState.phoneNumber}
          message={errorState.phoneNumber}
          onChange={(value) => { onFormValueChanged('phoneNumber', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Email*"
          name="email"
          value={formState.email}
          error={errorState.email}
          message={errorState.email}
          onChange={(value) => { onFormValueChanged('email', value); }} />
      </div>
      {formState.organizationId > 0 ?
        <div className="col-xs-12 col-sm-6 col-md-4">
          <TeamStaffRoleMultiselect
            label="Staff Role(s)*"
            name="staffRoles"
            value={formState.staffRoles}
            error={errorState.staffRoles}
            message={errorState.staffRoles}
            onChange={(value) => { onFormValueChanged('staffRoles', value); }}
            roleGroupId={getRoleGroupId(formState.organizationId)} />
        </div> : <Fragment />}
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Effective Date*"
          name="effectiveDate"
          value={formState.effectiveDate}
          error={errorState.effectiveDate}
          message={errorState.effectiveDate}
          onChange={(value) => { onFormValueChanged('effectiveDate', value); }}
          countOfYears={150}
          startYearOffset={-149} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Team Type*"
          name="teamTypeCode"
          options={teamTypeCodeOptions}
          value={formState.teamTypeCode}
          error={errorState.teamTypeCode}
          message={errorState.teamTypeCode}
          showEmptySelection={false}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'teamTypeCode', newValueLabel, 'teamTypeCodeName');
          }} />
      </div>
    </div>
  </Fragment>
);

const TeamStaffDetail = () => {
  const {
    state,
    orgUnitStaffState,
    personState,
    personGeneralState,
    dupGridState,
    newGridState,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked,
    onModalCancelClicked,
    onModalContinueClicked,
    onSelectGridPerson,
    onValueTextPairChanged,
    getRoleGroupIdByOrganizationId,
    teamTypeCodeOptions,
    setFormData
  } = useTeamStaffDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{state.isEdit ? 'Edit Staff' : 'Add Staff'}</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className={state.isEdit === true ? global.DisplayComponent : global.HideComponent}>
          <EditStaff
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
            getRoleGroupId={getRoleGroupIdByOrganizationId}
            teamTypeCodeOptions={teamTypeCodeOptions}
            onValueTextPairChanged={onValueTextPairChanged}
          />
        </div>
        <div className={state.isEdit === false ? global.DisplayComponent : global.HideComponent}>
          <Fragment>
            <div className="row">
              <div className="col-xs-12 usas-extra-bottom-margin">
                <YesNoSwitch
                  name="hasMemberId"
                  label={"Is the staff a USA Swimming member (and you have their Member Id)?"}
                  checked={formState.hasMemberId}
                  onChange={(checked) => { onFormValueChanged('hasMemberId', checked); }} />
              </div>
            </div>
            <div className={state.hasMemberId === true ? global.DisplayComponent : global.HideComponent}>
              <AddStaffByMemberId
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged}
                getRoleGroupId={getRoleGroupIdByOrganizationId}
                onValueTextPairChanged={onValueTextPairChanged}
                teamTypeCodeOptions={teamTypeCodeOptions}
                setFormData={setFormData}
              />
            </div>
            <div className={state.hasMemberId === false ? global.DisplayComponent : global.HideComponent}>
              <AddStaffWithoutMemberId
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged}
                getRoleGroupId={getRoleGroupIdByOrganizationId}
                onValueTextPairChanged={onValueTextPairChanged}
                teamTypeCodeOptions={teamTypeCodeOptions}
              />
            </div>
          </Fragment>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">{state.isEdit === true ? 'Save' : 'Add Staff'}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={orgUnitStaffState.isSaving || personState.isSaving || personGeneralState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={orgUnitStaffState.isArrayLoading || personState.isArrayLoading || personState.isObjLoading || personGeneralState.isObjLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={"Staff Dupe Check"}
        displayPopUp={dupGridState.length > 0}
        onModalCanceled={onModalCancelClicked}>
        <p><b>Database Matches Found</b></p>
        <TeamStaffDupGrid
          actionLabel={'Use Existing Person'}
          persons={dupGridState}
          onSelected={onSelectGridPerson} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin"></div>
        </div>
        <p><b>Info Provided By User</b></p>
        <TeamStaffDupGrid
          actionLabel={'Create New Person'}
          persons={newGridState}
          onSelected={onSelectGridPerson} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin"></div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalContinueClicked}>Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment >
  );
};

export default TeamStaffDetail;