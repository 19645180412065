import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';
import Constants from '../../../../common/utils/Constants';

import getTeamMeetData from './GetTeamMeetData';
import deleteMeetData from './DeleteMeetData';

const INITIAL_STATE = {
  orgUnitId: '',
  reportPeriodId: Constants.DEFAULT_ID,
  ...BASIC_INITIAL_STATE
};

const TeamMeetData = { INITIAL_STATE, getTeamMeetData, deleteMeetData };

export default TeamMeetData;