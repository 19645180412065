import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import searchTeamsData from './SearchTeamsData';
import getTeamData from './GetTeamData';
import putTeamData from './PutTeamData';
import postTeamData from './PostTeamData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const TeamData = {
  INITIAL_STATE,
  searchTeamsData,
  getTeamData,
  putTeamData,
  postTeamData
};

export default TeamData;