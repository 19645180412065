import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getOrgUnitStaffData from './GetOrgUnitStaffData';
import postOrgUnitStaffData from './PostOrgUnitStaffData';
import putOrgUnitStaffData from './PutOrgUnitStaffData';
import deleteOrgUnitStaffData from './DeletePersonOrgRoleDurationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OrgUnitStaffData = { INITIAL_STATE, getOrgUnitStaffData, postOrgUnitStaffData, putOrgUnitStaffData, deleteOrgUnitStaffData };

export default OrgUnitStaffData;