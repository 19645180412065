import { useContext } from 'react';

import { SearchTeamStateContext } from './SearchTeamContextProvider';

const useSearchTeamData = () => {
  const [searchTeamState, setSearchTeamState] = useContext(SearchTeamStateContext);

  return {
    searchTeamState,
    setSearchTeamState
  };
};

export default useSearchTeamData;