/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import global from '../../../common/components/GlobalStyle.module.css';

import PlusIcon from '../../../common/components/icons/PlusIcon';
import EditIcon from '../../../common/components/icons/EditIcon';

const LargeGrid = ({ state, onEdit }) => (
  <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
    <thead>
      <tr>
        <th>Team Name</th>
        <th>Team Code</th>
        <th>Organization</th>
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true
        ? (
          <tr>
            {state.isArrayLoading === true
              ? <td colSpan="3">Loading...</td>
              : <td colSpan="3">No Results</td>
            }
          </tr>
        ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
          ? state.arrayData.map((team, i) => (
            <tr key={i}>
              <td><a onClick={(e) => onEdit(e, team.orgUnitId)}>{team.orgUnitName || ''}</a></td>
              <td>{team.orgUnitCode || ''}</td>
              <td>{team.organizationName || ''} - {team.parentOrgUnitName || ''}</td>
            </tr>
          ))
          : (
            <tr>
              <td colSpan="3">No Results</td>
            </tr>
          )}
    </tbody>
  </table>
);

const SmallGrid = ({ state, onEdit, onCancelClicked }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Results</div>
            }
          </div>
        </div>
      )
      : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((team, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{team.orgUnitName}</span>&nbsp;
              {onCancelClicked ?
                <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, team.orgUnitId)}>
                  <PlusIcon />
                </button>
                :
                <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, team.orgUnitId)}>
                  <EditIcon />
                </button>}
            </div>
            <div className={['row', global.SmallTableRowInfo].join(' ')}>
              <div className={['col-sm-3 col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>Team Name</div>
              <div className={['col-sm-3 col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>{team.orgUnitName || <span>&nbsp;</span>}</div>
              <div className={['col-sm-3 col-xs-6', global.SmallTableRowLabels].join(' ')}>Team Code</div>
              <div className={['col-sm-3 col-xs-6', global.SmallTableRowData].join(' ')}>{team.orgUnitCode || <span>&nbsp;</span>}</div>
              <div className={['col-sm-3 col-xs-6', global.SmallTableRowLabels].join(' ')}>Organization</div>
              <div className={['col-sm-3 col-xs-6', global.SmallTableRowData].join(' ')}>{team.organizationName || <span>&nbsp;</span>} - {team.parentOrgUnitName || <span>&nbsp;</span>}</div>
            </div>
          </div>
        ))
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )}
  </div>
);

const SearchTeamGrid = ({ teamState, searchTeamState, onEdit, onToggleFilters, onCancelClicked }) => (
  <Fragment>
    {teamState.isArrayLoading === false
      ? <div className="row">
        <div className="col-xs-12 col-sm-10">
          {teamState.arrayData.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{teamState.arrayData.length === 1 ? `${teamState.arrayData.length} Search Result` : `${teamState.arrayData.length} Search Results`}</p>}
        </div>
        {onToggleFilters ?
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={() => onToggleFilters()}>{searchTeamState.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
          </div> : <Fragment />}
      </div>
      : <Fragment>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        </div>
      </Fragment >}
    <LargeGrid state={teamState} onEdit={onEdit} />
    <SmallGrid state={teamState} onEdit={onEdit} onCancelClicked={onCancelClicked} />
  </Fragment>
);

export default SearchTeamGrid;