import { useEffect } from 'react';
import useTeamStaffRolesData from '../../state/teamStaffRoles/UseTeamStaffRolesData';

const useTeamStaffRoleMultiselect = (roleGroupId) => {
  const { teamStaffRolesState, getTeamStaffRoles } = useTeamStaffRolesData(roleGroupId);

  useEffect(() => {
    if (roleGroupId > 0 && teamStaffRolesState.roleGroupId !== roleGroupId && teamStaffRolesState.isArrayLoading === false) {
      getTeamStaffRoles(roleGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamStaffRolesState, roleGroupId]);

  return {
    teamStaffRolesState
  };
};

export default useTeamStaffRoleMultiselect;