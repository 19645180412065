import { useEffect, useState } from "react";
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './TeamStaffDetailValidation';

import NavLinks from "../NavLinks";

import useTeamData from '../../../state/team/UseTeamData';

import useOrgUnitStaffData from "../../../../common/state/orgUnitStaff/UseOrgUnitStaffData";
import usePersonData from '../../../../common/state/person/UsePersonData';
import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';
import useEnvironmentVariableData from "../../../../common/state/environmentVariable/UseEnvironmentVariableData";
import useOrgRoleData from "../../../../common/state/orgRole/UseOrgRoleData";

import useForm from '../../../../common/utils/UseForm';
import Constants from "../../../../common/utils/Constants";
import { formatDate } from "../../../../common/utils/DateFunctions";

const useTeamStaffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { teamState } = useTeamData();
  const { orgUnitStaffState, postOrgUnitStaff, putOrgUnitStaff } = useOrgUnitStaffData();
  const { personState, getPersonByMemberId, getPotentialPersonDupes, postBasePerson } = usePersonData();
  const { personGeneralState, getPersonGeneral, putPersonGeneralContact, clearObjData } = usePersonGeneralData();
  const { environmentVariableState, UNKNOWN_COMPETITION_GENDER_ID, OTHER_PHONE_TYPE_ID, NCAA_STAFF_ROLE_GROUP_ID,
    FINA_STAFF_ROLE_GROUP_ID, NCAA_ORGANIZATION_ID, ISL_ORGANIZATION_ID, ISL_STAFF_ROLE_GROUP_ID,
    SWIM_A_THON_ORGANIZATION_ID, SWIMATHON_STAFF_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { orgRoleState, getOrgRoleDataByOrganizationId } = useOrgRoleData();
  const { handleSubmit, updateFormState, formState, errorState, setFormState, setIsDirty, onValueTextPairChanged, setFormData
  } = useForm(getFormInitialState(), submitFormCallback, validate);
  const [state, setState] = useState({ isEdit: false, hasMemberId: true });
  const [dupGridState, setDupGridState] = useState([]);
  const [newGridState, setNewGridState] = useState([]);

  const teamTypeCodeOptions = [
    { id: 'Mens', name: "Mens" },
    { id: 'Womens', name: "Womens" },
    { id: 'Combined', name: "Combined" }
  ];

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.TEAM_STAFF);
  };

  const onModalCancelClicked = () => {
    setDupGridState([]);
    setNewGridState([]);
    setIsDirty(true);
  };

  const onModalContinueClicked = () => {
    let matchFound = false;

    for (const dup of dupGridState) {
      if (dup.isSelected === true) {
        const roleGroupId = getRoleGroupIdByOrganizationId(formState.organizationId);

        postOrgUnitStaff(teamState.objData.orgUnitId, roleGroupId, createPostStaffObj(dup.personId, formState.effectiveDate, formState.staffRoles));

        matchFound = true;
        break;
      }
    }

    if (matchFound === false) {
      postBasePerson({
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        birthDate: formState.birthDate,
        email: formState.email.trim(),
        competitionGenderTypeId: UNKNOWN_COMPETITION_GENDER_ID,
        isDeleted: false
      });
    }

    setDupGridState([]);
    setNewGridState([]);
  };

  const onSelectGridPerson = (personId) => {
    let newDupGridState = [];
    for (const dup of dupGridState) {
      newDupGridState.push({
        ...dup,
        isSelected: personId === dup.personId ? true : false
      });
    }

    setDupGridState(newDupGridState);
    setNewGridState([{
      ...newGridState[0],
      isSelected: personId ? false : true
    }]);
  };

  useEffect(() => {
    if (orgRoleState.isArrayLoaded === false && orgRoleState.isArrayLoading === false) {
      getOrgRoleDataByOrganizationId(NCAA_ORGANIZATION_ID);
    }
  }, [orgRoleState, getOrgRoleDataByOrganizationId, NCAA_ORGANIZATION_ID]);

  useEffect(() => {
    if (state.hasMemberId !== formState.hasMemberId) {
      setState({ ...state, hasMemberId: formState.hasMemberId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.hasMemberId, formState.hasMemberId]);

  useEffect(() => {
    const personId = location.state?.personId;

    let hasHeadCoach = false;
    for (const staff of orgUnitStaffState.arrayData) {
      for (const staffRole of staff.personOrgRole) {
        if (staffRole.orgRoleName === "Head Coach") {
          hasHeadCoach = true;
          break;
        }
      }
    }


    //Edit
    if (personId) {
      const staffObj = orgUnitStaffState.arrayData.find(x => x.personId === location.state.personId);

      if (staffObj && Array.isArray(staffObj.personOrgRole)) {
        const effectiveDate = new Date(Math.min.apply(null, staffObj.personOrgRole.map(function (e) {
          return new Date(e.effectiveDate);
        })));

        const personOrgRolesArray = staffObj.personOrgRole;

        // TeamType personOrgRoleFields apply the same field value for each role for the selected person
        let fieldValue = "";
        personOrgRolesArray.forEach(function (role) {
          const personOrgRoleFieldsArray = role.personOrgRoleFields;

          if (personOrgRoleFieldsArray.length > 0) {
            personOrgRoleFieldsArray.forEach(function (field) {
              if (field.fieldName === "TeamType") {
                fieldValue = field.fieldValue;
              }
            });
          }
        });

        const organizationId = teamState.objData?.orgLevel?.organizationId;
        setFormState({
          ...formState,
          hasMemberId: false,
          staffRoles: staffObj.personOrgRole.map((x) => { return { id: x.orgRoleId, name: x.orgRoleName }; }),
          organizationId,
          personId: staffObj.personId,
          firstName: staffObj.firstName || '',
          lastName: staffObj.lastName || '',
          phoneNumber: staffObj.phoneNumber || '',
          email: staffObj.email || '',
          birthDate: staffObj.birthDate
            ? formatDate(staffObj.birthDate)
            : Constants.BLANK_DATE_STRING,
          effectiveDate: effectiveDate
            ? formatDate(effectiveDate)
            : Constants.BLANK_DATE_STRING,
          teamTypeCode: fieldValue,
          hasHeadCoach: hasHeadCoach
        });
        setIsDirty(true);
      }
      setState({ ...state, isEdit: true, hasMemberId: false });
    }
    //Add
    else {
      setFormState({
        ...formState,
        hasMemberId: true,
        organizationId: teamState.objData?.orgLevel?.organizationId,
        effectiveDate: formatDate(new Date()),
        hasHeadCoach: hasHeadCoach
      });
      setState({ ...state, isEdit: false, hasMemberId: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [teamState.objData]);

  useEffect(() => {
    if (personState.isArrayLoaded === true && environmentVariableState.isLoaded === true) {
      if (personState.arrayData.length > 0) {
        setDupGridState(personState.arrayData.map(x => {
          let orgDuration = { orgUnitName: '', effectiveDate: '', expirationDate: '' };
          if (Array.isArray(x.orgDurations) && x.orgDurations.length > 0) {
            orgDuration = {
              orgUnitName: x.orgDurations[0].orgUnitName,
              effectiveDate: x.orgDurations[0].effectiveDate,
              expirationDate: x.orgDurations[0].expirationDate
            };
          }

          return {
            personId: x.personId,
            firstName: x.firstName,
            lastName: x.lastName,
            birthDate: x.birthDate,
            orgDuration,
            isSelected: false
          };
        }));
        setNewGridState([{
          personId: '',
          firstName: formState.firstName,
          lastName: formState.lastName,
          birthDate: formState.birthDate,
          orgDurations: { organization: '', effectiveDate: '', expirationDate: '' },
          isSelected: true
        }]);
      } else {
        postBasePerson({
          firstName: formState.firstName.trim(),
          lastName: formState.lastName.trim(),
          birthDate: formState.birthDate,
          email: formState.email.trim(),
          competitionGenderTypeId: UNKNOWN_COMPETITION_GENDER_ID,
          isDeleted: false
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.isArrayLoaded, environmentVariableState]);

  useEffect(() => {
    if (personState.isSaving === false && personState.isSaved === true) {
      getPersonGeneral(personState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState]);

  useEffect(() => {
    if (personGeneralState.isObjLoaded === true && personGeneralState.isObjLoading === false &&
      personGeneralState.isSaving === false && personGeneralState.isSaved === false) {
      putPersonGeneralContact(personGeneralState.objData.personId, createPutPersonGeneralObj(JSON.parse(JSON.stringify(personGeneralState.objData)), formState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState]);

  useEffect(() => {
    if ((personGeneralState.isSaving === false && personGeneralState.isSaved === true) ||
      (personState.isObjLoading === false && personState.isObjLoaded === true)) {
      if (orgUnitStaffState.isSaving === false && orgUnitStaffState.isSaved === false) {
        const roleGroupId = getRoleGroupIdByOrganizationId(formState.organizationId);

        postOrgUnitStaff(teamState.objData.orgUnitId, roleGroupId, createPostStaffObj(personState.objData.personId, formState.effectiveDate, formState.staffRoles));

        if (personGeneralState.isSaved === true) {
          clearObjData();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState, personState]);

  useEffect(() => {
    if (orgUnitStaffState.isSaving === false && orgUnitStaffState.isSaved === true) {
      navigate(NavLinks.TEAM_STAFF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitStaffState]);

  function submitFormCallback() {
    //Edit
    if (formState.personId) {
      const roleGroupId = getRoleGroupIdByOrganizationId(formState.organizationId);

      putOrgUnitStaff(teamState.objData.orgUnitId, formState.personId, roleGroupId, createPutStaffObj(formState));
    }
    //Add
    else {
      if (formState.hasMemberId === true) {
        getPersonByMemberId(formState.memberId);
      }
      else {
        getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
      }
    }
  };

  function createPutPersonGeneralObj(personGeneralObjData, formState) {
    personGeneralObjData.personContact[0].personContactPhone.push({
      personContactId: personGeneralObjData.personContact[0].personContactId,
      phoneTypeId: OTHER_PHONE_TYPE_ID,
      phoneNumber: formState.phoneNumber,
      isPrimary: true,
      phoneType: undefined
    });
    return personGeneralObjData;
  };

  function getOrgRoleFieldIdMap(orgRoleArray) {
    let idMapObj = {};
    for (const role of orgRoleArray) {
      if (role.orgRoleField !== undefined) {
        const orgRoleFieldArray = role.orgRoleField;
        for (const field of orgRoleFieldArray) {
          if (field.fieldName === "TeamType") {
            idMapObj[field.orgRoleId] = { id: field.orgRoleFieldId };
          }
        }
      }
    }
    return idMapObj;
  }

  function createPostStaffObj(personId, effectiveDate, staffRoles) {
    return {
      personId,
      personOrgRole: staffRoles.map(x => {
        const idMapObj = getOrgRoleFieldIdMap(orgRoleState.arrayData);

        const orgRoleFieldId = idMapObj[x.id]?.id;
        const fields = [];

        if (orgRoleFieldId) {
          let fieldsObj = {
            fieldName: "TeamType",
            orgRoleFieldId,
            fieldValue: formState.teamTypeCode
          }
          fields.push(fieldsObj);
        }

        return { orgRoleId: x.id, orgRoleName: x.name, effectiveDate, personOrgRoleFields: fields };
      })
    };
  };

  function createPutStaffObj(formState) {

    const newPersonOrgRole = [];
    const currentStaffMember = orgUnitStaffState.arrayData.find(x => x.personId === formState.personId);
    const idMapObj = getOrgRoleFieldIdMap(orgRoleState.arrayData);

    if (currentStaffMember && Array.isArray(currentStaffMember.personOrgRole)) {
      for (const newRole of formState.staffRoles) {
        const oldRole = currentStaffMember.personOrgRole.find(x => x.orgRoleId === newRole.id);

        let fields = [];

        if (oldRole) {
          const orgRoleFieldId = idMapObj[oldRole.orgRoleId]?.id;

          if (orgRoleFieldId) {
            let fieldsObj = {
              fieldName: "TeamType",
              orgRoleFieldId,
              fieldValue: formState.teamTypeCode
            };
            fields.push(fieldsObj);
          }

          if (formState.effectiveDate !== formatDate(oldRole.effectiveDate)) {
            oldRole.effectiveDate = formState.effectiveDate
          }

          oldRole.personOrgRoleFields = fields;
          newPersonOrgRole.push(oldRole);
        } else {
          const orgRoleFieldId = idMapObj[newRole.id]?.id;

          if (orgRoleFieldId) {
            let fieldsObj = {
              fieldName: "TeamType",
              orgRoleFieldId,
              fieldValue: formState.teamTypeCode
            }
            fields.push(fieldsObj);
          }

          newPersonOrgRole.push({ orgRoleId: newRole.id, orgRoleName: newRole.name, effectiveDate: formState.effectiveDate, personOrgRoleFields: fields });
        }
      }
    } else {
      for (const role of formState.staffRoles) {
        newPersonOrgRole.push({ orgRoleId: role.id, orgRoleName: role.name, effectiveDate: formState.effectiveDate, personOrgRoleField: [] });
      }
    }

    return {
      personId: formState.personId,
      email: formState.email,
      personOrgRole: newPersonOrgRole
    };
  };

  function getRoleGroupIdByOrganizationId(organizationId) {
    if (organizationId === NCAA_ORGANIZATION_ID) {
      return NCAA_STAFF_ROLE_GROUP_ID;
    } else if (organizationId === ISL_ORGANIZATION_ID) {
      return ISL_STAFF_ROLE_GROUP_ID;
    } else if (organizationId === SWIM_A_THON_ORGANIZATION_ID) {
      return SWIMATHON_STAFF_ROLE_GROUP_ID;
    } else { //default to FINA
      return FINA_STAFF_ROLE_GROUP_ID;
    }
  };

  return {
    state,
    teamState,
    orgUnitStaffState,
    personState,
    personGeneralState,
    dupGridState,
    newGridState,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onCancelClicked,
    onModalCancelClicked,
    onModalContinueClicked,
    onSelectGridPerson,
    onValueTextPairChanged,
    getRoleGroupIdByOrganizationId,
    teamTypeCodeOptions,
    setFormData
  };
};

export default useTeamStaffDetail;

function getFormInitialState() {
  return {
    organizationId: Constants.DEFAULT_ID,
    personId: '',
    staffRoles: [],
    hasMemberId: true,
    memberId: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    birthDate: Constants.BLANK_DATE_STRING,
    effectiveDate: Constants.BLANK_DATE_STRING,
    teamTypeCode: '',
    teamTypeCodeName: '',
    hasHeadCoach: false
  };
};