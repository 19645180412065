import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetParentOrgUnitByOrgLevelData = (id, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const idForUrl = id ? encodeURIComponent(id) : 'NaN';
    const url = `/OrgUnit/GetParentOrgUnitForOrgLevel/${idForUrl}/OrgUnit`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          //Sort data in A-Z order
          arrayData.sort(function (a, b) {
            const textA = a.orgUnitName.toUpperCase();
            const textB = b.orgUnitName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            items: arrayData.map((x) => { return { id: x.orgUnitId, name: x.orgUnitName};}),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoaded: false,
          isArrayLoading: false,
          message: message
        });
      });
  }
};

export default GetParentOrgUnitByOrgLevelData;