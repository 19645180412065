import React, { Fragment } from 'react';

import useMeetUpload from './UseMeetUpload';

import MeetResultsUpload from '../../../../../common/components/fileUpload/meetResultsUpload/MeetResultsUpload';
import Headings from '../../../../../common/components/headings/Headings';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import global from '../../../../../common/components/GlobalStyle.module.css'

const MeetUpload = () => {
  const {
    meetId,
    meetHeaderState,
    onBackClicked,
    meetStartDate,
    meetEndDate
  } = useMeetUpload();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Upload</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p className={global.HeaderText}><b>Meet Name:</b> {meetHeaderState.meetName}</p>
          <p className={global.HeaderText}><b>Meet Host:</b> {meetHeaderState.meetHost}</p>
          <p className={global.HeaderText}><b>Meet Dates:</b> {meetHeaderState.meetDates}</p>
        </div>
      </div>
      {meetId > 0 &&
        <MeetResultsUpload meetId={meetId} meetStartDate={meetStartDate} meetEndDate={meetEndDate} />
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
        </div>
      </div>
    </Fragment>
  );
};

export default MeetUpload;