import {
  isValidMemberId, isValidName, isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber,
  isValidEmail, isValidDate, isValidBirthDate
} from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  //Add staff validation only
  if (formState.personId === '') {
    //Add staff by Member Id validation
    if (formState.hasMemberId === true) {
      if (formState.memberId.trim().length === 0) {
        errors.memberId = 'Member Id is required';
      } else if (isValidMemberId(formState.memberId.trim()) === false) {
        errors.memberId = 'A valid Member Id is required';
      }
    }
    //Add staff without Member Id validation
    else {
      if (formState.firstName.trim() === '') {
        errors.firstName = 'First Name is required';
      } else if (!isValidName(formState.firstName.trim())) {
        errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
      }

      if (formState.lastName.trim() === '') {
        errors.lastName = 'Last Name is required';
      } else if (!isValidName(formState.lastName.trim())) {
        errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
      }

      //Phone Number
      if (!formState.phoneNumber) {
        errors.phoneNumber = 'Phone Number is required';
      } else if (!isValidUSOrCanadianPhoneNumber(formState.phoneNumber) && !isValidMexicanPhoneNumber(formState.phoneNumber)) {
        errors.phoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format';
      }

      if (formState.email.trim() === '') {
        errors.email = 'Email Address is required';
      } else if (!isValidEmail(formState.email.trim())) {
        errors.email = 'Email Address must be in a valid format';
      }

      if (formState.birthDate === Constants.BLANK_DATE_STRING) {
        errors.birthDate = 'Birth Date is required';
      } else if (!isValidDate(formState.birthDate)) {
        errors.birthDate = 'Birth Date must be a valid date';
      } else if (!isValidBirthDate(formState.birthDate)) {
        errors.birthDate = 'Birth Date cannot be in the future';
      }      
    }
  }

  if (formState.teamTypeCode.length === 0) {
    errors.teamTypeCode = 'Team Type is required';
  }

  //Staff Roles & Effective Date validation needed for both types of add & edit
  if (formState.effectiveDate === Constants.BLANK_DATE_STRING) {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  } else if (!isValidBirthDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date cannot be in the future';
  }

  if (formState.staffRoles.length < 1) {
    errors.staffRoles = 'Must select at least one staff role';
  }

  return errors;
};

const TeamStaffDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TeamStaffDetailValidation;