import React, { Fragment } from 'react';

import useMeetAdd from './UseMeetAdd';

import Headings from '../../../../../common/components/headings/Headings';
import Input from '../../../../../common/components/inputs/Input';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import MultiSelect from '../../../../../common/components/multiSelect/MultiSelect';
import MeetClassificationTreeView from '../../../../../common/components/tree/meetClassificationTree/MeetClassificationTreeView';
import MeetSoftwareDropdown from '../../../../../common/components/dropdowns/meetSoftwareDropdown/MeetSoftwareDropdown';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';

import global from '../../../../../common/components/GlobalStyle.module.css';

const MeetAdd = () => {
  const {
    teamState,
    meetState,
    state,
    formState,
    errorState,
    meetClassificationState,
    meetSoftwareState,
    courseState,
    courseOptionsState,
    facilityOptionsState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    Constants
  } = useMeetAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Meet</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Meet Name*"
              name="meetName"
              value={formState.meetName}
              error={errorState.meetName}
              message={errorState.meetName}
              onChange={(value) => { onFormValueChanged('meetName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker label="Start Date*"
              name="startDate"
              value={formState.startDate}
              error={errorState.startDate}
              message={errorState.startDate}
              onChange={(value) => { onFormValueChanged('startDate', value); }}
              countOfYears={154}
              startYearOffset={-149} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker label="End Date*"
              name="endDate"
              value={formState.endDate}
              error={errorState.endDate}
              message={errorState.endDate}
              onChange={(value) => { onFormValueChanged('endDate', value); }}
              countOfYears={154}
              startYearOffset={-149} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <MeetClassificationTreeView
              label="Meet Classification*"
              name="meetClassification"
              value={formState.meetClassification}
              error={errorState.meetClassification}
              message={errorState.meetClassification}
              selectableLeavesOnly={true}
              singleSelect={true}
              onChange={(newValue) => { onFormValueChanged('meetClassification', newValue); }}
              orgUnitId={teamState.objData.orgUnitId}
              filterId={-1} 
              filterMode={"exclude_filter_id"}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <MeetSoftwareDropdown
              label="Meet Software"
              name="meetSoftwareId"
              value={formState.meetSoftwareId}
              error={errorState.meetSoftwareId}
              message={errorState.meetSoftwareId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'meetSoftwareId', newValueLabel, 'meetSoftwareName', e); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4" >
            <MultiSelect
              label="Meet Course(s)*"
              name="meetCourse"
              value={formState.meetCourse}
              error={errorState.meetCourse}
              message={errorState.meetCourse}
              options={courseOptionsState}
              isLoading={courseOptionsState.length === 0}
              onChange={(value) => { onFormValueChanged('meetCourse', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Facility*"
              name="facilityId"
              value={formState.facilityId}
              error={errorState.facilityId}
              message={errorState.facilityId}
              options={facilityOptionsState}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'facilityId', newValueLabel, 'facilityName', e); }} />
          </div>
          <div className={formState.facilityId > 0 ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="Altitude (ft)"
                name="facilityAltitude"
                value={state.facilityAltitude !== '' ? state.facilityAltitude.toString() : 'Unknown'} />
            </div>
          </div>
          <div className={formState.facilityId > 0 ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="City"
                name="facilityCity"
                value={state.facilityCity} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="State"
                name="facilityState"
                value={state.facilityState} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-bottom-margin">
              <ReadOnly
                label="Country"
                name="facilityCountry"
                value={state.facilityCountry} />
            </div>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={meetClassificationState.isArrayLoading || meetSoftwareState.isLoading || courseState.isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={meetState.isSaving} />
    </Fragment>
  );
};

export default MeetAdd;