import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useTeamData from '../../../state/team/UseTeamData';
import useTeamFacilityLinksData from '../../../state/teamFacilityLinks/UseTeamsFacilityLinksData';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';
import Constants from '../../../../common/utils/Constants';

const useTeamFacility = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { teamState } = useTeamData();
  const { facilityState, deleteFacilityPool } = useFacilityData();
  const { teamFacilityLinksState, deleteTeamFacilityLinks, getTeamFacilityLinks } = useTeamFacilityLinksData();
  const [modalState, setModalState] = useState(getInitialModalState());
  const [poolModalState, setPoolModalState] = useState(getInitialPoolModalState());
  const [state, setState] = useState({ tryGet: false });

  const onDeleteFacilityFromTeam = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteTeamFacilityLinks(teamState.objData.orgUnitId, modalState.orgUnitFacilityId);
    setModalState({
      ...getInitialModalState()
    });
    setState({ ...state, tryGet: true });
  };
  
  const onAddFacility = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.TEAM_FACILITY_SEARCH);
  };

  const onEditFacility = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.TEAM_FACILITY_DETAIL, { state: { facilityId: facilityId } });
  };

  const onAddPool = (e, facilityId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.TEAM_FACILITY_POOL_DETAIL, { state: { facilityId: facilityId } });
  };

  const onEditPool = (e, facilityId, poolId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.TEAM_FACILITY_POOL_DETAIL, { state: { facilityId: facilityId, poolId: poolId } });
  };

  const onDeletePool = (e, pool) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setPoolModalState({
      ...poolModalState,
      displayPopup: true,
      poolId: pool.facilityPoolId,
      poolName: pool.facilityPoolName,
      poolDimensions: pool.poolDimensions.dimensionName
    });
  };

  const onClickDeletePool = (e, poolId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setPoolModalState(getInitialPoolModalState());
    deleteFacilityPool(poolId);
  };

  const onPoolModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setPoolModalState(getInitialPoolModalState());
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState(getInitialModalState());
  };

  const onOpenModalClicked = (e, facilityId, facilityName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const targetIndex = teamFacilityLinksState.arrayData.findIndex((orgUnitFacility) => orgUnitFacility.facilityId === facilityId);

    if (targetIndex >= 0) {
      setModalState({
        ...modalState,
        orgUnitFacilityId: teamFacilityLinksState.arrayData[targetIndex].orgUnitFacilityId,
        displayPopUp: true,
        facilityId: facilityId,
        facilityName: facilityName,
        facilityCity: teamFacilityLinksState.arrayData[targetIndex].facility.city,
        facilityStateCode: teamFacilityLinksState.arrayData[targetIndex].facility.stateCode,
        facilityPostalCode: teamFacilityLinksState.arrayData[targetIndex].facility.postalCode,
        targetIndex
      });
    }
  };

  useEffect(() => {
    if (state.tryGet === true) {
      if (teamState.isObjLoaded === true && teamFacilityLinksState.isArrayLoading === false
        && teamFacilityLinksState.isSaving === false && facilityState.isSaving === false) {
        getTeamFacilityLinks(teamState.objData.orgUnitId);
        setState({ ...state, tryGet: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, teamState, teamFacilityLinksState, facilityState]);

  useEffect(() => {
    if (teamFacilityLinksState.isArrayLoaded === false) {
      if (teamState.isObjLoaded === true && teamFacilityLinksState.isArrayLoading === false && teamFacilityLinksState.isSaving === false) {
        getTeamFacilityLinks(teamState.objData.orgUnitId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamFacilityLinksState, teamState]);

  useEffect(() => {
    if (location.state && (location.state.addedPool === true || location.state.hasNewFacilityDetails === true)) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    teamFacilityLinksState,
    teamState,
    facilityState,
    modalState,
    poolModalState,
    onAddFacility,
    onEditFacility,
    onAddPool,
    onEditPool,
    onDeletePool,
    onClickDeletePool,
    onPoolModalCanceled,
    onModalCanceled,
    onOpenModalClicked,
    onDeleteFacilityFromTeam
  };
};

export default useTeamFacility;

function getInitialModalState() {
  return {
    orgUnitFacilityId: Constants.DEFAULT_ID,
    displayPopUp: false,
    modalTitle: 'Unlink Facility?',
    facilityId: Constants.DEFAULT_ID,
    facilityName: '',
    facilityCity: '',
    facilityStateCode: '',
    facilityPostalCode: '',
    targetIndex: undefined
  };
};

function getInitialPoolModalState() {
  return {
    modalTitle: 'Delete this Pool from the Facility?',
    displayPopup: false,
    poolId: Constants.DEFAULT_ID,
    poolName: '',
    poolDimensions: ''
  };
};