import React, { Fragment } from 'react';

import useTeamAthleteAdvancedWriteRoster from './UseTeamAthleteAdvancedWriteRoster';

import TeamAthleteRosterAdvancedWriteGrid from './TeamAthleteRosterAdvancedWriteGrid';

import ReportPeriodTreeView from '../../../../../common/components/tree/reportPeriodTree/ReportPeriodTreeView';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ClubRosterExport from '../../../../../common/components/fileExports/ClubRosterExport';
import global from '../../../../../common/components/GlobalStyle.module.css';

const TeamAthleteRosterAdvancedWrite = () => {
  const { rolloverState, state, filterState, setFilterState, teamState, orgUnitAthleteState, gridState, exportState, modalState,
    Constants, onAdd, onEdit, onDelete, onOpenModalClicked, onModalCanceled, onRolloverCheckbox, onRolloverRoster, 
    canRollover } = useTeamAthleteAdvancedWriteRoster();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Team Athlete Roster</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAdd}>Add Athlete to Roster</ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-4 pull-right">
          <ReportPeriodTreeView
            label="Report Period"
            name="reportPeriod"
            value={filterState.reportPeriod}
            selectableLeavesOnly={false}
            singleSelect={true}
            onChange={(newValue) => {
              setFilterState({ ...filterState, reportPeriod: newValue });
            }}
            organizationId={teamState.objData?.orgLevel?.organizationId || Constants.DEFAULT_ID}
          />
        </div>
        <div className='col-xs-12 col-sm-4'>
          <ClubRosterExport
            clubRosterArray={exportState}
            orgUnitCode={teamState.objData.orgUnitCode || ""}
            orgUnitName={teamState.objData.orgUnitName || ""}
            parentOrgUnitCode={teamState.objData.parentOrgUnitCode || ""}
            clubCity={""}
            clubStateCode={""}
            clubAddress1={""}
            clubPostalCode={""}
            clubEmail={""}
            rosterType={"NCAA"}
          />
        </div>
        {rolloverState.rolloverPersons.length > 0 &&
        <div className="col-xs-12 col-sm-4">
          <button className={global.PlainButton} type='button' onClick={onRolloverRoster}>Rollover Selected Athletes</button>
        </div>
        }
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <TeamAthleteRosterAdvancedWriteGrid
            state={orgUnitAthleteState}
            gridState={gridState}
            onEdit={onEdit}
            onDelete={onOpenModalClicked}
            organizationState={state}
            onRolloverCheckbox={onRolloverCheckbox}
            canRollover={canRollover}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>The Athlete's Expiration Date will be set to today's date.</b></p>
            <p><b>Athlete:</b> {modalState.athleteInfo}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDelete}>Make Athlete Inactive</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={orgUnitAthleteState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={orgUnitAthleteState.isArrayLoading} />
    </Fragment>
  );
};

export default TeamAthleteRosterAdvancedWrite;