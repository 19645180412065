import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useTeamData from '../../../state/team/UseTeamData';
import useSearchTeamData from '../../../state/searchTeam/UseSearchTeamData';

import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useSearchFacilityData from '../../../../common/state/searchFacility/UseSearchFacilityData';
import useFacilityData from '../../../../common/state/facility/UseFacilityData';

const INITIAL_VIEW_STATE = {
  text: 'Search',
  isItemSelected: false
};

const TAXONOMIES = ['OtherTeamDetail'];
const SCOPE = 'Team';

const useTeamSearch = () => {
  const navigate = useNavigate();
  const { teamState, getTeam } = useTeamData();
  const { searchTeamState } = useSearchTeamData();
  const { resetSearchFacilityState } = useSearchFacilityData();
  const { clearFacilityArrayData, clearFilterState } = useFacilityData();
  const { getContextSecurity } = useSecurityData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  
  const onClick = (e, orgUnitId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const selectedOrgUnitId = orgUnitId;
    getTeam(selectedOrgUnitId);
    getContextSecurity(selectedOrgUnitId, TAXONOMIES, SCOPE);
    setState({ ...state, isItemSelected: true });
  };

  useEffect(() => {
    //Clear out any facility search data
    clearFilterState();
    clearFacilityArrayData();
    resetSearchFacilityState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.isItemSelected === true) {
      if (teamState.isObjLoading !== true && teamState.isObjLoaded === true) {
        if (teamState.objData.orgUnitId) {
          navigate(NavLinks.TEAM_STAFF);
        } else {
          setState(INITIAL_VIEW_STATE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, teamState]);

  return { teamState, searchTeamState, onClick, teamAddLink: NavLinks.TEAM_ADD };
};

export default useTeamSearch;