const NavLinks = {
  TEAM_STAFF: '/team/staff',
  TEAM_STAFF_DETAIL: '/team/staff/detail',
  TEAM_MEET: '/team/meet',
  TEAM_MEET_ADD: '/team/meet/add',
  TEAM_MEET_ADD_SESSIONS: '/team/meet/add/sessions',
  TEAM_MEET_ADD_SESSIONS_DETAIL: '/team/meet/add/sessions/detail',
  TEAM_MEET_UPLOAD: '/team/meet/uploadresults',
  TEAM_FACILITY: '/team/facility',
  TEAM_FACILITY_SEARCH: '/team/facility/search',
  TEAM_FACILITY_DETAIL: '/team/facility/details',
  TEAM_FACILITY_ADD: '/team/facility/add',
  TEAM_FACILITY_POOL_DETAIL: '/team/facility/pool/detail',
  TEAM_ATHLETEROSTER: '/team/athleteroster',
  TEAM_ATHLETEROSTER_DETAIL: '/team/athleteroster/detail',
  TEAM_ATHLETE_ROSTER_ADVANCED: '/team/athleteroster',
  TEAM_ATHLETE_ROSTER_ADVANCED_DETAIL: '/team/athleteroster/detail',
  TEAM_SEARCH: '/team/search',
  TEAM_ROOT: '/team',
  TEAM_SELECTION: '/team/myteam/selection',
  TEAM_ADD: '/team/add',
  TEAM_TEAM_CHANGES: '/team/teamchanges',
  TEAM_DOWNLOADOTHERTEAMROSTER: '/team/downloadotherteamroster'
};

export default NavLinks;