import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useTeamData from '../../../state/team/UseTeamData';
import useTeamMeetData from '../../../state/team/teamMeet/UseTeamMeetData';

import useReportPeriodData from '../../../../common/state/reportPeriod/UseReportPeriodData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useReportPeriodFlatData from '../../../../common/state/reportPeriodFlat/UseReportPeriodFlatData';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useMeet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({ isNCAA: false, isISL: false, isCSS: false, initialLoadComplete: false });
  const [filterState, setFilterState] = useState({ reportPeriod: [] });
  const [modalState, setModalState] = useState(getInitialModalState());
  const { teamState } = useTeamData();
  const { teamMeetState, getTeamMeet, deleteMeet, resetTeamMeetState } = useTeamMeetData();
  const { reportPeriodState } = useReportPeriodData();
  const { reportPeriodFlatState, getReportPeriodsFlatByOrganizationId } = useReportPeriodFlatData();
  const { environmentVariableState, NCAA_ORGANIZATION_ID, CSS_ORGANIZATION_ID, NCAA_SEASON_START_MONTH_DAY,
    ISL_ORGANIZATION_ID } = useEnvironmentVariableData();
  const [gridState, setGridState] = useState([]);

  const onAddMeet = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.TEAM_MEET_ADD);
  };

  const onUploadResults = (e, meet) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.TEAM_MEET_UPLOAD, { state: { meet: meet } });
  };

  const onDeleteMeet = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    deleteMeet(modalState.meetId);
    setModalState({
      ...getInitialModalState()
    });
  };

  const onOpenModalClicked = (e, meet) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const meetInfo = meet.meetName + ' (Host: ' + meet.orgUnitName + ', Meet Dates: ' + formatDate(meet.startDate) + ' - ' + formatDate(meet.endDate) + ')';
    setModalState({
      ...modalState,
      meetId: meet.meetId,
      meetInfo: meetInfo,
      displayPopUp: true
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState(getInitialModalState());
  };

  useEffect(() => {
    if (teamMeetState.isArrayLoading === false && teamMeetState.isArrayLoaded === true) {
      const today = new Date();
      const newMeetArray = [];

      for (const meet of teamMeetState.arrayData) {
        const meetStartDate = new Date(meet.startDate);

        newMeetArray.push({
          ...meet,
          isPastOrCurrentMeet: today >= meetStartDate
        });
      }

      setGridState(newMeetArray);
    }
  }, [teamMeetState]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && Object.keys(teamState.objData).length > 0) {
      if (teamState.objData?.orgLevel?.organizationId === NCAA_ORGANIZATION_ID) {
        setState({ ...state, isNCAA: true, isISL: false, isCSS: false });
      }
      else if (teamState.objData?.orgLevel?.organizationId === ISL_ORGANIZATION_ID) {
        setState({ ...state, isNCAA: false, isISL: true, isCSS: false });
      }
      else if (teamState.objData?.orgLevel?.organizationId === CSS_ORGANIZATION_ID) {
        setState({ ...state, isNCAA: false, isISL: false, isCSS: true });
      }
      else {
        setState({ ...state, isNCAA: false, isISL: false, isCSS: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [environmentVariableState, teamState.objData]);

  useEffect(() => {
    //Get flat report periods to filter by date easier
    if (Object.keys(teamState.objData).length > 0 && reportPeriodFlatState.isLoading !== true
      && teamState.objData?.orgLevel?.organizationId > 0
      && teamState.objData?.orgLevel?.organizationId !== reportPeriodFlatState.organizationId) {
      getReportPeriodsFlatByOrganizationId(teamState.objData.orgLevel.organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [teamState.objData, reportPeriodFlatState]);

  useEffect(() => {
    if (reportPeriodState.isArrayLoading === false && reportPeriodState.isArrayLoaded === true
      && environmentVariableState.isLoaded === true) {
      const today = new Date();
      const month = today.getMonth();
      const year = today.getFullYear().toString();
      let todayFormatted;
      //Handle April (3) through August (7) gap for NCAA seasons
      if (state.isNCAA === true && month >= 3 && month <= 7) {
        const startDate = year + '/' + NCAA_SEASON_START_MONTH_DAY;
        todayFormatted = new Date(formatDate(new Date(startDate)));
      }
      else {
        todayFormatted = new Date(formatDate(today));
      }

      const currentReportPeriods = [];
      const futureReportPeriods = [];
      let treeArrayDataIndex = [];
      let startDate;
      let endDate;
      for (let i = 0; i < reportPeriodState.arrayData.length; i++) {
        const selectedArrayData = reportPeriodState.arrayData[i];
        if (state.isNCAA === true || state.isISL === true || state.isCSS === true) {
          startDate = new Date(formatDate(selectedArrayData.StartDate));
          endDate = new Date(formatDate(selectedArrayData.EndDate));
          if (todayFormatted <= endDate && todayFormatted >= startDate) {
            currentReportPeriods.push(selectedArrayData);
          }
          if (todayFormatted < startDate) {
            futureReportPeriods.push(selectedArrayData);
          }
        }
        else {
          if (selectedArrayData.children) {
            for (let i = 0; i < selectedArrayData.children.length; i++) {
              startDate = new Date(formatDate(selectedArrayData.children[i].StartDate));
              endDate = new Date(formatDate(selectedArrayData.children[i].EndDate));
              if (todayFormatted <= endDate && todayFormatted >= startDate) {
                currentReportPeriods.push(selectedArrayData.children[i]);
                const index = reportPeriodState.arrayData.findIndex(x => x.id === selectedArrayData.id);
                treeArrayDataIndex.push(index);
              }
              if (todayFormatted < startDate) {
                futureReportPeriods.push(selectedArrayData.children[i]);
              }
            }
          }
        }
      }
      const treeArrayData = JSON.parse(JSON.stringify(reportPeriodState.treeData));
      if (currentReportPeriods.length > 0) {
        if (state.isNCAA === true || state.isISL === true  || state.isCSS === true) {
          const currentOption = treeArrayData.find(x => x.id === currentReportPeriods[0].reportPeriodId);
          const currentOptionFormatted = [{ id: currentOption.id, name: currentOption.name }];
          setFilterState({ ...filterState, reportPeriod: currentOptionFormatted });
        }
        else {
          const currentOption = treeArrayData[treeArrayDataIndex[0]].children.find(x => x.id === currentReportPeriods[0].reportPeriodId);
          const currentOptionFormatted = [{ id: currentOption.id, name: currentOption.name }];
          setFilterState({ ...filterState, reportPeriod: currentOptionFormatted });
        }
      }
      else if (futureReportPeriods.length > 0 && (state.isNCAA === true || state.isISL === true  || state.isCSS === true)) {
        const futureOption = treeArrayData.find(x => x.id === futureReportPeriods[0].reportPeriodId);
        const futureOptionFormatted = [{ id: futureOption.id, name: futureOption.name }];
        setFilterState({ ...filterState, reportPeriod: futureOptionFormatted });
      }
      else {
        if (state.isNCAA === true || state.isISL === true  || state.isCSS === true) {
          const currentOption = treeArrayData[treeArrayData.length - 1];
          const currentOptionFormatted = [{ id: currentOption.id, name: currentOption.name }];
          setFilterState({ ...filterState, reportPeriod: currentOptionFormatted });
        }
        else if (treeArrayData.length > 0) {
          const currentOption = treeArrayData[treeArrayData.length - 1];
          const currentOptionFormatted = [{ id: currentOption.id, name: currentOption.name }];
          setFilterState({ ...filterState, reportPeriod: currentOptionFormatted });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [reportPeriodState, state.isNCAA, state.isISL, state.isCSS, environmentVariableState]);

  useEffect(() => {
    if (teamMeetState.isSaving === false && teamMeetState.isSaved === true) {
      resetTeamMeetState();
    } else if (
      teamMeetState.isArrayLoading === false &&
      filterState.reportPeriod &&
      filterState.reportPeriod[0]?.id > 0 &&
      (
        teamMeetState.isArrayLoaded === false || (teamMeetState.orgUnitId !== teamState.objData.orgUnitId) ||
        (teamMeetState.reportPeriodId !== filterState.reportPeriod[0]?.id) ||
        (location.state?.getMeets === true && state.initialLoadComplete === false)
      )
    ) {
      const crossOrganization = teamState.objData?.orgLevel?.organizationId === NCAA_ORGANIZATION_ID ? false : undefined;

      getTeamMeet(teamState.objData.orgUnitId, filterState.reportPeriod[0]?.id, crossOrganization);

      if (location.state?.getMeets === true && state.initialLoadComplete === false) {
        setState({ ...state, initialLoadComplete: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMeetState, filterState.reportPeriod]);

  return {
    state,
    reportPeriodFlatState,
    filterState,
    setFilterState,
    teamState,
    teamMeetState,
    gridState,
    modalState,
    Constants,
    onAddMeet,
    onUploadResults,
    onDeleteMeet,
    onOpenModalClicked,
    onModalCanceled
  };
};

export default useMeet;

function getInitialModalState() {
  return {
    displayPopUp: false,
    meetId: '',
    meetInfo: '',
    modalTitle: 'Delete Meet?'
  };
};