import { useEffect, useState } from 'react';

import useTeamData from '../../../state/team/UseTeamData';

const INITIAL_VIEW_STATE = {
  reportParameters: { orgUnitId: '' },
  contextId: '',
  routeName: ''
};

const useTeamReporting = () => {
  const [ viewState, setViewState ] = useState({...INITIAL_VIEW_STATE});
  const { teamState } = useTeamData();

  useEffect(() => {
    if (teamState.objData?.orgUnitId) {
      setViewState({
        ...viewState,
        reportParameters: {orgUnitId: teamState.objData.orgUnitId},
        contextId: teamState.objData.orgUnitId,
        routeName: 'TEAM_REPORTING'
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamState.objData]);

  return {...viewState};
};

export default useTeamReporting;