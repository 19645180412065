
import React, { Fragment } from 'react';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import useOrgUnitSelection from './UseOrgUnitSelection';

const OrgUnitSelection = () => {
    const { NavLinks, TAXONOMIES, SCOPE, getTeam, MY_NCAA_TEAM_ROLE_GROUP_ID } = useOrgUnitSelection();
    return (
        <Fragment>
            <SelectOrgUnit
                pageTitle={'My Teams'}
                navLink={NavLinks.TEAM_STAFF}
                roleGroupId={MY_NCAA_TEAM_ROLE_GROUP_ID}
                getFunction={getTeam}
                TAXONOMIES={TAXONOMIES}
                SCOPE={SCOPE} />
        </Fragment>
    );
};

export default OrgUnitSelection;