import React, { Fragment } from 'react';

import useTeamStaff from './UseTeamStaff';

import TeamStaffReadGrid from './TeamStaffReadGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const TeamStaffReadOnly = () => {
  const {
    orgUnitStaffState,
    showMemberId
  } = useTeamStaff();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Team Staff</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <TeamStaffReadGrid
            state={orgUnitStaffState}
            showMemberId={showMemberId} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={orgUnitStaffState.isArrayLoading} />
    </Fragment>
  );
};

export default TeamStaffReadOnly;