import NavLinks from '../NavLinks';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useTeamData from '../../../state/team/UseTeamData';

const useOrgUnitSelection = () => {
    const TAXONOMIES = ['OtherTeamDetail'];
    const SCOPE = 'Team';
    const { MY_NCAA_TEAM_ROLE_GROUP_ID } = useEnvironmentVariableData();
    const { getTeam } = useTeamData();

    return {
        NavLinks,
        getTeam,
        TAXONOMIES,
        SCOPE,
        MY_NCAA_TEAM_ROLE_GROUP_ID
    };
};

export default useOrgUnitSelection;