import React, { createContext, useState } from 'react';

import SearchTeamData from './SearchTeamData';

export const SearchTeamStateContext = createContext();

const SearchTeamContextProvider = ({ children }) => {
  const stateHook = useState(SearchTeamData.INITIAL_STATE);

  return (
    <SearchTeamStateContext.Provider value={stateHook}>
      {children}
    </SearchTeamStateContext.Provider>
  );
};

export default SearchTeamContextProvider;