import useReportPeriodTreeView from './UseReportPeriodTreeView';

import TreeView from '../../tree/TreeView';

import Constants from '../../../utils/Constants';

import global from '../../GlobalStyle.module.css';

const ReportPeriodTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange, organizationId }) => {
  const { reportPeriodState } = useReportPeriodTreeView(organizationId || Constants.DEFAULT_ID);

  return reportPeriodState.message
    ? <span className={global.LoadingMsg}>{reportPeriodState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={reportPeriodState.isArrayLoading}
      options={reportPeriodState.treeData}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default ReportPeriodTreeView;