import { useState } from 'react';

import OtherOrgOrgUnitTopLevelsData from './OtherOrgOrgUnitTopLevelsData';

const useOtherOrgOrgUnitTopLevelsData = () => {
  const [otherOrgOrgUnitTopLevelsState, setOtherOrgOrgUnitTopLevelsState] = useState(OtherOrgOrgUnitTopLevelsData.INITIAL_STATE);

  const getOtherOrgOrgUnitTopLevels = () => 
    OtherOrgOrgUnitTopLevelsData.getOtherOrgOrgUnitTopLevelsData(otherOrgOrgUnitTopLevelsState, setOtherOrgOrgUnitTopLevelsState);

  return {
    otherOrgOrgUnitTopLevelsState,
    getOtherOrgOrgUnitTopLevels
  };
};

export default useOtherOrgOrgUnitTopLevelsData;