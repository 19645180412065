import useTeamData from '../../../state/team/UseTeamData';

import useFacilityData from '../../../../common/state/facility/UseFacilityData';

const useTeamFacilityPoolDetail = () => {
  const { facilityState } = useFacilityData();
  const { teamState } = useTeamData();

  return {
    teamState,
    facilityState
  };
};

export default useTeamFacilityPoolDetail;