import React, { Fragment } from 'react';

import useTeamStaff from './UseTeamStaff';

import TeamStaffWriteGrid from './TeamStaffWriteGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Constants from '../../../../common/utils/Constants';

const TeamStaffWrite = () => {
  const {
    orgUnitStaffState,
    deleteModalState,
    showMemberId,
    onDeleteRole,
    onAddStaff,
    onEditStaff,
    onModalCancelClicked,
    onModalDeleteClicked
  } = useTeamStaff();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Team Staff</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddStaff}>Add Staff</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <TeamStaffWriteGrid
            state={orgUnitStaffState}
            showMemberId={showMemberId}
            onDelete={onDeleteRole}
            onEdit={onEditStaff} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Remove Staff Member?'}
        displayPopUp={deleteModalState.displayModal}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{`Removing ${deleteModalState.personName}'s last role will remove them from this team's staff`}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalDeleteClicked}>Remove Staff Member</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={orgUnitStaffState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={orgUnitStaffState.isArrayLoading} />
    </Fragment>
  );
};

export default TeamStaffWrite;