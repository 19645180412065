import React, { createContext, useState } from 'react';

import TeamStaffRolesData from './TeamStaffRolesData';

export const TeamStaffRolesContext = createContext();

const TeamStaffRolesContextProvider = ({ children }) => {
  const stateHook = useState(TeamStaffRolesData.INITIAL_STATE);
  
  return (
    <TeamStaffRolesContext.Provider value={stateHook}>
      {children}
    </TeamStaffRolesContext.Provider>
  );
};

export default TeamStaffRolesContextProvider;