import {BASIC_INITIAL_STATE} from '../../../common/utils/HttpHelper';

import getOtherOrgOrgUnitTopLevelsData from './GetOtherOrgOrgUnitTopLevelsData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE, items: []
};

const OtherOrgOrgUnitTopLevelsData = {
  INITIAL_STATE, getOtherOrgOrgUnitTopLevelsData
};

export default OtherOrgOrgUnitTopLevelsData;