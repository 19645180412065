import {BASIC_INITIAL_STATE} from '../../../common/utils/HttpHelper';

import getParentOrgUnitByOrgLevelData from './GetParentOrgUnitByOrgLevelData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE, items: []
};

const ParentOrgUnitStateData = {
  INITIAL_STATE, getParentOrgUnitByOrgLevelData
};

export default ParentOrgUnitStateData;