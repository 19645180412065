export const localValidate = (formState) => {
  let errors = {};

  if (formState.teamOrgUnitName.trim() === '') {
    errors.teamOrgUnitName = 'Team Name is Required';
  } else if (formState.teamOrgUnitName.trim().length > 100) {
    errors.teamOrgUnitName = 'Team Name cannot exceed 100 characters';
  }

  if (formState.teamOrgUnitCode.trim() === '') {
    errors.teamOrgUnitCode = 'Team Code is Required';
  } else if (formState.teamOrgUnitCode.trim().length > 15) {
    errors.teamOrgUnitCode = 'Team Code cannot exceed 15 characters';
  }

  return errors;
};

const TeamChangesValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TeamChangesValidation;