import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';
import Checkbox from '../../../../../common/components/checkboxes/Checkbox';

const today = formatDate(new Date());
const todayFormatted = new Date(today);

const LargeGrid = ({ state, gridState, onEdit, onDelete, organizationState, onRolloverCheckbox, canRollover }) => (
  <table className={[global.UsasTable, 'visible-lg visible-md'].join(' ')}>
    <thead>
      <tr>
        <th>Athlete Name</th>
        <th>Member Id</th>
        <th>Age</th>
        <th>Competition Category</th>
        <th>Sport</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        {organizationState.isNCAA === true ?
          <>
            <th>Year of Eligibility</th>
            <th>Rollover</th>
          </>
          : <Fragment />}
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true
        ? (
          <tr>
            {organizationState.isNCAA === true ?
              <Fragment>
                {state.isArrayLoading ?
                  <td colSpan="7">Loading...</td>
                  : <td colSpan="7">No Athletes</td>
                }
              </Fragment>
              :
              <Fragment>
                {state.isArrayLoading ?
                  <td colSpan="6">Loading...</td>
                  : <td colSpan="6">No Athletes</td>
                }
              </Fragment>}
          </tr>
        ) : Array.isArray(gridState) && gridState.length > 0
          ? gridState.map((athlete, i) => {
            // const athleteName = `${athlete.lastName}, ${athlete.preferredName || athlete.firstName}`;
            return (
              <tr key={i}>
                <td>{athlete.preferredName !== '' ? athlete.preferredName : athlete?.firstName} {athlete.lastName}</td>
                <td>{athlete?.memberId}</td>
                <td>{athlete.age}</td>
                <td>{athlete.competitionGenderTypeName}</td>
                <td>{athlete.sportType}</td>
                <td>{formatDate(athlete.effectiveDate)}</td>
                <td>{formatDate(athlete.expirationDate)}</td>
                {organizationState.isNCAA === true ?
                  <>
                    <td>{athlete.yearOfEligibility ? athlete.yearOfEligibility : ''}</td>
                    <td>
                      {canRollover && athlete.yearOfEligibility !== 'Grad Student' &&
                        <Checkbox
                          label=""
                          name="rolloverPerson"
                          onChange={(e) => { onRolloverCheckbox(e, athlete.personId, athlete.personOrgRoleDurationId) }}
                          checked={athlete.rolloverPerson}
                        />
                      }
                    </td>
                  </>
                  : <Fragment />}
                <td>
                  {organizationState.isNCAA === true ?
                    <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, athlete, athlete.personId)}>
                      <EditIcon />
                    </button> : <Fragment />}
                  {new Date(formatDate(athlete.expirationDate)) > todayFormatted ?
                    < button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, athlete)}>
                      <DeleteIcon />
                    </button> : <Fragment />}
                </td>
              </tr>
            );
          }) : (
            <Fragment>
              {organizationState.isNCAA === true ?
                <tr>
                  <td colSpan="7">No Athletes</td>
                </tr>
                :
                <tr>
                  <td colSpan="6">No Athletes</td>
                </tr>
              }
            </Fragment>
          )
      }
    </tbody>
  </table >
);

const SmallGrid = ({ state, gridState, onEdit, onDelete, organizationState, onRolloverCheckbox, canRollover }) => (
  <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Athletes</div>}
          </div>
        </div>
      ) : Array.isArray(gridState) && gridState.length > 0
        ? gridState.map((athlete, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}><span className='hidden-xs'>{athlete.preferredName !== '' ? athlete.preferredName : athlete?.firstName} {athlete.lastName}</span>&nbsp;
              {new Date(formatDate(athlete.expirationDate)) > todayFormatted ?
                <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, athlete)}>
                  <DeleteIcon />
                </button> : <Fragment />}
              {organizationState.isNCAA === true ?
                <button className={new Date(formatDate(athlete.expirationDate)) > todayFormatted ? global.IconButtonMobileMargin : global.IconButton} type="button" onClick={(e) => onEdit(e, athlete, athlete.personId)}>
                  <EditIcon />
                </button> : <Fragment />}
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className="row">
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Athlete Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{athlete.preferredName !== '' ? athlete.preferredName : athlete?.firstName} {athlete.lastName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Member Id</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{athlete.memberId}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Age</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{athlete.age}</div>
                <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{athlete.competitionGenderTypeName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Effective Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatDate(athlete.effectiveDate)}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Expiration Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatDate(athlete.expirationDate)}</div>
                {organizationState.isNCAA === true ?
                  <Fragment>
                    <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Year of Eligibility</div>
                    <div className={['col-xs-6 col-sm-3', global.SmallTableRowData, global.Multiline].join(' ')}>{athlete.yearOfEligibility ? athlete.yearOfEligibility : <span>&nbsp;</span>}</div>
                    {canRollover && athlete.yearOfEligibility !== 'Grad Student' &&
                    <>
                      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Rollover</div>
                      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData, global.Multiline].join(' ')}>
                        <Checkbox
                          label=""
                          name="rolloverPerson"
                          onChange={(e) => { onRolloverCheckbox(e, athlete.personId, athlete.personOrgRoleDurationId) }}
                          checked={athlete.rolloverPerson}
                        />
                      </div>
                    </>
                    }
                  </Fragment> : <Fragment />}
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Athletes</div>
            </div>
          </div>
        )
    }
  </div >
);

const TeamAthleteRosterWriteGrid = ({ state, gridState, onEdit, onDelete, organizationState, onRolloverCheckbox, canRollover }) => (
  <Fragment>
    <LargeGrid state={state} gridState={gridState} onEdit={onEdit} onDelete={onDelete} organizationState={organizationState} onRolloverCheckbox={onRolloverCheckbox} canRollover={canRollover} />
    <SmallGrid state={state} gridState={gridState} onEdit={onEdit} onDelete={onDelete} organizationState={organizationState} onRolloverCheckbox={onRolloverCheckbox} canRollover={canRollover} />
  </Fragment>
);

export default TeamAthleteRosterWriteGrid;