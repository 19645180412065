import { useEffect } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import validate from './SearchTeamValidation';

import useTeamData from '../../state/team/UseTeamData';
import useSearchTeamData from '../../state/searchTeam/UseSearchTeamData';
import useOtherOrgOrgUnitData from '../../state/otherOrgOrgUnit/UseOtherOrgOrgUnitData';

import useSecurityData from '../../../common/state/security/UseSecurityData';

import useForm from '../../../common/utils/UseForm';
import HierarchicalDataUtils from '../../../common/utils/HierarchicalDataUtils';

const sortByOptions = [
  { id: 1, name: "Team Name (A-Z)", value: 'orgUnitName' },
  { id: 2, name: "Parent Name (A-Z)", value: 'parentOrgUnitName' },
  { id: 3, name: "Organization Name (A-Z)", value: 'organizationName' }];

const useSearchTeam = ({ teamAddLink }) => {
  const navigate = useNavigate();
  const { teamState, searchTeams, teamFiltersState } = useTeamData();
  const { searchTeamState, setSearchTeamState } = useSearchTeamData();
  const { otherOrgOrgUnitState, getOtherOrgOrgUnits } = useOtherOrgOrgUnitData();
  const { contextSecurityState } = useSecurityData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors, onValueTextPairChanged,
    setIsDirty, isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onToggleFilters = () => {
    setSearchTeamState({ ...searchTeamState, showFilters: !searchTeamState.showFilters });
    setIsDirty(true);
  }

  const onAddClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(teamAddLink);
  };

  //Sets initial form state once
  useEffect(() => {
    if (otherOrgOrgUnitState.isArrayLoaded === false) {
      getOtherOrgOrgUnits();
    }
    if (otherOrgOrgUnitState.isArrayLoaded === true) {
      setFormState(getInitialFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherOrgOrgUnitState.isArrayLoaded]);

  useEffect(() => {
    if (Object.keys(teamFiltersState.filterObject).length > 0
      && otherOrgOrgUnitState.isArrayLoaded === true) {
      const oldSearchValues = JSON.parse(JSON.stringify(teamFiltersState.filterObject))
      let sortByIndex = undefined;
      for (let i = 0; i < sortByOptions.length; i++) {
        if (teamFiltersState.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }

      setFormState({
        ...formState,
        ...oldSearchValues,
        orgUnitId: undefined,
        orgUnit: HierarchicalDataUtils.GetNameIdPairs(otherOrgOrgUnitState.treeData, [oldSearchValues.orgUnitId]) || [],
        sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
        sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
      });
      setIsDirty(true);

      if (teamState.isArrayLoading === false) {
        searchTeams(teamFiltersState.filterObject, teamFiltersState.sortBy || '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherOrgOrgUnitState.isArrayLoaded]);

  useEffect(() => {
    if (searchTeamState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTeamState.showFilters, isDirty]);

  function submitFormCallback(formState) {
    setSearchTeamState({ ...searchTeamState, tryRedirect: false });

    let sortBy = undefined;
    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    if (teamState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchTeams(filterObject, sortBy || '', {});
    }
  };

  function createSearchFilterObj(formState) {
    return {
      orgUnitId: formState.orgUnit.length > 0 ? formState.orgUnit[0].id : undefined,
      teamOrgUnitId: formState.teamOrgUnitId ? formState.teamOrgUnitId : undefined,
      teamOrgUnitName: formState.teamOrgUnitName.trim() || undefined,
      coachLastName: formState.coachLastName.trim() || undefined
    };
  }

  function getInitialFormState() {
    return {
      orgUnit: [],
      teamOrgUnitId: '',
      teamOrgUnitName: '',
      coachLastName: '',
      sortById: sortByOptions[0].id,
      sortByName: sortByOptions[0].name,
      orgLevel: [],
      parentOrgUnitId: '',
      parentOrgUnitName: ''
    }
  };

  return {
    otherOrgOrgUnitState,
    contextSecurityState,
    formState,
    errorState,
    teamState,
    searchTeamState,
    setSearchTeamState,
    clearForm,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    setIsDirty,
    onToggleFilters,
    sortByOptions,
    onAddClicked
  }
}

export default useSearchTeam;