import React, { Fragment } from 'react';

import useTeamAthleteRoster from '../UseTeamAthleteRoster';

import TeamAthleteRosterReadGrid from './TeamAthleteRosterReadGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ReportPeriodTreeView from '../../../../../common/components/tree/reportPeriodTree/ReportPeriodTreeView';

import Constants from '../../../../../common/utils/Constants';

const TeamAthleteRosterReadOnly = () => {
  const { state, gridState, orgUnitAthleteState, filterState, setFilterState, teamState } = useTeamAthleteRoster();
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Team Athlete Roster</Headings.H3>
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12 col-sm-6">
          <ReportPeriodTreeView
            label="Report Period"
            name="reportPeriod"
            value={filterState.reportPeriod}
            selectableLeavesOnly={false}
            singleSelect={true}
            onChange={(newValue) => {
              setFilterState({ ...filterState, reportPeriod: newValue });
            }}
            organizationId={teamState.objData?.orgLevel?.organizationId || Constants.DEFAULT_ID}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <TeamAthleteRosterReadGrid
            state={orgUnitAthleteState}
            gridState={gridState}
            organizationState={state}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={orgUnitAthleteState.isArrayLoading} />
    </Fragment>
  );
};

export default TeamAthleteRosterReadOnly;