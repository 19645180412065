import React, { createContext, useState } from 'react';

import TeamData from './TeamData';

export const TeamStateContext = createContext();

const TeamContextProvider = ({ children }) => {
  const stateHook = useState(TeamData.INITIAL_STATE);

  return (
    <TeamStateContext.Provider value={stateHook}>
      {children}
    </TeamStateContext.Provider>
  );
};

export default TeamContextProvider;