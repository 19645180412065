import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import { useEffect, useState } from 'react';

import NavLinks from '../NavLinks';

import validate from './TeamAddValidation';

import useOrganizationOrgLevelData from '../../../state/organizationOrgLevelTree/UseOrganizationOrgLevelData';
import useTeamData from '../../../state/team/UseTeamData';

import useSecurityData from '../../../../common/state/security/UseSecurityData';

import useForm from '../../../../common/utils/UseForm';
import ToIntIfInt from '../../../../common/utils/ToIntIfInt';
import { DEFAULT_ID } from '../../../../common/utils/Constants';

const INITIAL_VIEW_STATE = {
  isItemSelected: false
};

const TAXONOMIES = ['OtherTeamDetail'];
const SCOPE = 'Team';
const TEAM_ADD_RESTRICTED_ORGS = ['LMS Organization', 'Learn To Swim'];

const UseTeamAdd = () => {
  const navigate = useNavigate();
  const { organizationOrgLevelState, getOrganizationOrgLevels } = useOrganizationOrgLevelData();
  const { teamState, postTeam, getTeam } = useTeamData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const { formState, errorState, onFormValueChanged, onValueTextPairChanged, handleSubmit } = useForm(initialState, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  function submitFormCallback() {
    const orgUnitObj = createOrgUnitObj();
    postTeam(orgUnitObj);
  };

  const createOrgUnitObj = () => {
    return {
      orgLevelId: ToIntIfInt(formState.orgLevel[0].id),
      orgUnitName: formState.teamName.trim(),
      orgUnitShortName: formState.teamShortName.trim(),
      orgUnitCode: formState.teamCode.trim(),
      parentOrgUnitId: formState.parentOrgUnitId
    };
  };

  const onBack = () => {
    navigate(NavLinks.TEAM_SEARCH);
  };

  useEffect(() => {
    if (organizationOrgLevelState.isArrayLoaded === false) {
      getOrganizationOrgLevels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationOrgLevelState.isArrayLoaded]);

  useEffect(() => {
    if (teamState.isSaved === true) {
      getTeam(teamState.objData.orgUnitId);
      getContextSecurity(teamState.objData.orgUnitId, TAXONOMIES, SCOPE);
      setState({ ...state, isItemSelected: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamState.isSaved]);

  useEffect(() => {
    if (state.isItemSelected === true) {
      if (teamState.isObjLoading !== true && teamState.isObjLoaded === true) {
        if (teamState.objData.orgUnitId) {
          navigate(NavLinks.TEAM_STAFF);
        } else {
          setState(INITIAL_VIEW_STATE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, teamState]);

  function filterOrgLevelTreeData(treeData) {
    const filterTreeData = [];

    for(const level of treeData) {
      if (!TEAM_ADD_RESTRICTED_ORGS.includes(level.name)) {
        filterTreeData.push(level);
      }
    }

    return filterTreeData;
  };

  return {
    teamState,
    contextSecurityState,
    formState,
    errorState,
    treeData: filterOrgLevelTreeData(organizationOrgLevelState.treeData),
    isTreeLoading: organizationOrgLevelState.isArrayLoading === true,
    selectedOrgLevel: formState.orgLevel.length > 0 ? ToIntIfInt(formState.orgLevel[0].id) : DEFAULT_ID,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onBack
  };

  function initialState() {
    return {
      orgLevel: [],
      parentOrgUnitId: DEFAULT_ID,
      parentOrgUnitName: '',
      teamCode: '',
      teamName: '',
      teamShortName: ''
    };
  };
};

export default UseTeamAdd;