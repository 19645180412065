import { useEffect, useState } from 'react';

import useParentOrgUnitData from '../../state/orgUnitShort/UseParentOrgUnitData';

import { DEFAULT_ID } from '../../../common/utils/Constants';

const useParentOrgUnitCombobox = ({ orgLevelBelow }) => {
  const { parentOrgUnitState, getParentOrgUnits, resetState } = useParentOrgUnitData();
  const [state, setState] = useState({ orgLevelId: DEFAULT_ID });

  useEffect(() => {
    if (orgLevelBelow !== state.orgLevelId) {
      resetState();
    }
    if (orgLevelBelow !== DEFAULT_ID
      && parentOrgUnitState.isArrayLoaded === false
      && parentOrgUnitState.isArrayLoading === false) {
      getParentOrgUnits(orgLevelBelow);
      setState({ orgLevelId: orgLevelBelow });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgLevelBelow, parentOrgUnitState.isArrayLoaded]);

  return { parentOrgUnitState };
};

export default useParentOrgUnitCombobox;