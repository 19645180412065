import { useEffect } from 'react';

import useReportPeriodData from '../../../../common/state/reportPeriod/UseReportPeriodData';

const useReportPeriodTreeView = (organizationId) => {
  const { reportPeriodState, getReportPeriodsByOrganizationId } = useReportPeriodData();

  useEffect(() => {
    if (organizationId > 0) {
      getReportPeriodsByOrganizationId(organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return {
    reportPeriodState
  };
};

export default useReportPeriodTreeView;