import React, { Fragment } from 'react';

import useTeamAthleteAdvancedWriteDetail from './UseTeamAthleteAdvancedWriteDetail';

import TeamAthleteRosterDupGrid from '../TeamAthleteRosterDupGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Input from '../../../../../common/components/inputs/Input';
import CompetitionCategoryRadiobuttonList from '../../../../../common/components/radiobuttonlists/competitionCategoryRadiobuttonList/CompetitionCategoryRadiobuttonList';

import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';

const TeamAthleteAdvancedWriteDetail = () => {
  const {
    state,
    orgUnitAthleteState,
    personState,
    newGridState,
    dupGridState,
    competitionGenderState,
    onModalContinueClicked,
    onModalCancelClicked,
    onSelectGridPerson,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked,
    yearOfEligibilityOptions,
    ncaaOrgRoleOptions
  } = useTeamAthleteAdvancedWriteDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{state.isEdit ? 'Edit Athlete' : 'Add Athlete'}</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Input
                label="First Name*"
                name="firstName"
                value={formState.firstName}
                error={errorState.firstName}
                message={errorState.firstName}
                onChange={(value) => { onFormValueChanged('firstName', value); }} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Input
                label="Last Name*"
                name="lastName"
                value={formState.lastName}
                error={errorState.lastName}
                message={errorState.lastName}
                onChange={(value) => { onFormValueChanged('lastName', value); }} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              {state.isSet === true &&
                <DatePicker label="Birth Date*"
                  name="birthDate"
                  value={formState.birthDate}
                  error={errorState.birthDate}
                  message={errorState.birthDate}
                  onChange={(value) => { onFormValueChanged('birthDate', value); }}
                  countOfYears={state.isNCAA === true ? 9 : 40}
                  startYearOffset={state.isNCAA === true ? -25 : -50} />
              }
            </div>
            <div className={"col-xs-12 col-sm-6 col-md-4"}>
              {state?.isEdit ?
                (<ReadOnly
                  label="Competition Gender"
                  name="competitionGenderTypeName"
                  value={formState.competitionGenderTypeName} />)
                : (<CompetitionCategoryRadiobuttonList
                  label="Competition Category*"
                  name="competitionGenderTypeId"
                  isVertical={false}
                  value={Number(formState.competitionGenderTypeId)}
                  error={errorState.competitionGenderTypeId}
                  message={errorState.competitionGenderTypeId}
                  onChange={(value) => { onFormValueChanged('competitionGenderTypeId', Number(value)); }} />)
              }
            </div>
            {state.isNCAA === true ?
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Dropdown
                  label="Year of Eligibility*"
                  name="yearOfEligibilityId"
                  options={yearOfEligibilityOptions}
                  value={formState.yearOfEligibilityId}
                  error={errorState.yearOfEligibilityId}
                  message={errorState.yearOfEligibilityId}
                  showEmptySelection={false}
                  isLoading={false}
                  onChange={(newValue, newValueLabel, e) => {
                    e && e.target && e.target.value &&
                      onValueTextPairChanged(newValue, 'yearOfEligibilityId', newValueLabel, 'yearOfEligibilityName');
                  }} />
              </div>
              : <Fragment />}
            {state.isNCAA === false && formState.orgRoleStatus !== 'International Team Athlete'
              ? <div className="col-xs-12 col-sm-6 col-md-4">
                <YesNoSwitch
                  name="hasRepresentedIntComp"
                  label={"Has Represented International Competition?"}
                  checked={formState.hasRepresentedIntComp}
                  onChange={(checked) => { onFormValueChanged('hasRepresentedIntComp', checked); }} />
              </div>
              : <Fragment />}
            {state.isNCAA === true &&
              <div className="col-xs-12 col-sm-6 col-md-4">
                {
                  // leaving readonly item for now in case we change our minds again
                  state.isEdit === false || state.isEdit === true ?
                    (
                      <Dropdown
                        label="NCAA Sport*"
                        name="ncaaOrgRoleId"
                        options={ncaaOrgRoleOptions}
                        value={formState.ncaaOrgRoleId}
                        error={errorState.ncaaOrgRoleId}
                        message={errorState.ncaaOrgRoleId}
                        showEmptySelection={false}
                        isLoading={false}
                        onChange={(newValue, newValueLabel, e) => {
                          e && e.target && e.target.value &&
                            onValueTextPairChanged(newValue, 'ncaaOrgRoleId', newValueLabel, 'ncaaOrgRoleName');
                        }} />
                    ) :
                    (<ReadOnly
                      label="NCAA Sport"
                      name="ncaaOrgRoleName"
                      value={formState.ncaaOrgRoleName} />
                    )
                }
              </div>
            }
          </div>
        </Fragment>

        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>{state.isEdit ? "Save" : "Add Athlete"}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form >
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={orgUnitAthleteState.isSaving || personState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={orgUnitAthleteState.isArrayLoading || personState.isArrayLoading || competitionGenderState.isArrayLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={"Athlete Dupe Check"}
        displayPopUp={dupGridState.length > 0}
        onModalCanceled={onModalCancelClicked}>
        <p><b>Database Matches Found</b></p>
        <span style={{ color: 'steelblue' }}><b>Matching information is highlighted</b></span>
        <TeamAthleteRosterDupGrid
          actionLabel={'Use Existing Person'}
          persons={dupGridState}
          onSelected={onSelectGridPerson}
          enteredFirstName={newGridState[0]?.firstName}
          enteredLastName={newGridState[0]?.lastName}
          enteredBirthDate={newGridState[0]?.birthDate}
          conditionalFormatting={true} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin"></div>
        </div>
        <p><b>Info Provided By User</b></p>
        <TeamAthleteRosterDupGrid
          actionLabel={'Create New Person'}
          persons={newGridState}
          onSelected={onSelectGridPerson}
          conditionalFormatting={false} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin"></div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onModalContinueClicked}>Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment >
  );
};

export default TeamAthleteAdvancedWriteDetail;