import { useEffect, useState } from 'react';
import useOrgUnitAthleteData from "../../../../../common/state/orgUnitAthlete/UseOrgUnitAthleteData";
import useForm from '../../../../../common/utils/UseForm';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useTeamData from '../../../../state/team/UseTeamData';
import validate from './OtherTeamAthleteRosterValidation';

const useOtherTeamAthleteRoster = () => {
  const { orgUnitAthleteState, getOrgUnitAthletePiiSafe } = useOrgUnitAthleteData();
  const { teamStateNoContext, getTeamNoContext } = useTeamData();
  const { formState, errorState, handleSubmit, onValueTextPairChanged }
    = useForm(
      getInitialFormState(),
      submitFormCallback,
      validate);
  const [exportState, setExportState] = useState([]);
  const { environmentVariableState, NCAA_ORGANIZATION_ID } = useEnvironmentVariableData();

  useEffect(() => {
    if (formState.teamOrgUnitId.length > 0 && teamStateNoContext.isObjLoading === false && environmentVariableState.isLoaded) {
      getTeamNoContext(formState.teamOrgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, environmentVariableState]);

  useEffect(() => {
    if (teamStateNoContext.isObjLoaded === true) {
      //get additional info about the selected team
      getOrgUnitAthletePiiSafe(teamStateNoContext.objData.orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamStateNoContext]);

  useEffect(() => {
    if (orgUnitAthleteState.isArrayLoaded === true) {
      let currentAthletes = [];
      const today = new Date();
      orgUnitAthleteState.arrayData.forEach(athlete => {
        const expirationDate = new Date(formatDate(athlete.expirationDate));

        if (expirationDate > today) {
          currentAthletes.push(athlete);
        }
      })

      setExportState(currentAthletes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitAthleteState]);

  function submitFormCallback() {};

  function getInitialFormState() {
    return {
      teamOrgUnitId: '',
      teamOrgUnitName: ''
    }
  };

  return {
    formState,
    errorState,
    exportState,
    teamStateNoContext,
    orgUnitAthleteState,
    handleSubmit,
    onValueTextPairChanged,
    NCAA_ORGANIZATION_ID
  }
}

export default useOtherTeamAthleteRoster;